//zh
export default {
    CANCEL: "取消",
    CONFIRM: "确认",
    LOGIN: '登录',
    NAME: '名称',
    AVATAR: "头像",
    'LISTING LIST': '广告列表',
    EMAIL: "邮箱",
    'GENERATING':'生成中...',
    'CREDENTIAL INSPECTION': '证件审核',
    "PLEASE INPUT {NAME}": "请输入{NAME}",
    'TENANT ADDED SUCCESSFULLY': '租客添加成功',
    'TENANT ADDED FAILED': '租客添加失败',
    'UPLOAD DOCUMENT': '上传证件',
    'DOCUMENT IMAGE(BACK)': '证件照片(背面)',
    'DOCUMENT IMAGE(FRONT)': '证件照片(正面)',
    "PLEASE SELECT {NAME}": "请选择{NAME}",
    'LISTING': '广告',
    'LANGUAGE': 'Language',
    'UEN': 'UEN号码',
    'DRAFT': '广告草稿',
    'CHECKING DOCUMENT VALIDITY...': '检查证件有效性...',
    'CREATING DOCUMENT...': '创建证件中...',
    "WELCOME TO SINGHOMEJOY": "欢迎来到SingHomeJoy",
    VERIFY: "邮箱验证",
    'COMPANY PROFILE': '公司档案',
    'COMPANY MEMBERS': '公司成员',
    'AND': '和',
    'SERVICE AGREEMENT': '服务协议',
    'I HAVE READ AND AGREE TO THE HOMEJOY': '我已阅读并同意HomeJoy的',
    'HOMEPAGE PREVIEW': '首页预览',
    'MY COMPANY': '我的公司',
    'CONTRACTS': '合同数',
    'COMPANY DETAILS': '公司详情',
    'WEBSITE': '网站',
    'SAVE AS DRAFT': '保存为草稿',
    'POSITION': '职位',
    'ACTION': '操作',
    'UNIT DETAIL': '房屋详情',
    'LAP POOL': '游泳池',
    'NO LISTINGS HAVE BEEN ADDED YET.': '目前还没有添加任何广告。',
    'WELCOME TO {WEBNAME}': '欢迎来到{WEBNAME}',
    'LISTING DETAILS': '广告详情',
    'SINGAPORE COMPANY': '© 2024 新加坡 SingHome Joy Pte. Ltd.',
    'CHINESE COMPANY': '深圳市星家悦商务有限公司战略合作伙伴',
    'COMPANY ADDRESS':'罗宾逊路14号，#08-01A, 远东金融大厦, 新加坡,  邮编 048545',
    'SIGN UP FOR A NEW ACCOUNT': '注册一个新帐户',
    'PET ALLOWED': '是否允许养宠物',
    'LOOKS LIKE YOU DON`T HAVE AN ACCOUNT WITH THAT EMAIL ADDRESS, SIGN UP TO GET ALL OF THESE BENEFITS:': '您似乎没有该电子邮件地址的帐户，请注册以获得所有这些好处：',
    'LOG IN WITH VERIFICATION CODE': '使用验证码登录',
    "PLEASE INPUT EMAIL": "请输入邮箱",
    "PLEASE INPUT YOUR EMAIL": "请填写你的邮箱",
    "PLEASE INPUT PASSWORD": "请填写你的密码",
    "LOOKS LIKE YOU DON'T HAVE AN ACCOUNT WITH THAT EMAIL ADDRESS, SIGN UP TO GET ALL OF THESE BENEFITS:": "看起来您还没有为你的邮箱创建账户，注册以获得所有这些好处:",
    "MY ACCOUNT": "我的账户",
    'ACCOUNT CREATED': '创建账户',
    'ACCESS CONTROL': '访问权管理',
    'PROPERTY INFORMATION': '房屋信息',
    'AC MAINTANENCE': '空调维护管理',
    'CLEANING': '房屋清洁管理',
    'RENOVATION' : '房屋装修管理',
    'MY LEASES': '合同管理',
    "DO YOU WANT TO USE THE LAST LOGIN EMAIL?": "是否使用上次登录的邮箱？",
    'CREATE TA TEMPLATE': '创建合同模板',
    'CREATE TA': '创建合同',
    'TA MANAGEMENT': '合同管理',
    'TENANT PROFILE': '租客信息管理',
    'MY FINANCE': '财务管理',
    'MASTER LEASE MANAGEMENT': '母租约管理',
    'MONTHLY PAYMENT MANAGEMENT': '月供管理',
    'UTILITIES MANAGEMENT': '水电费管理',
    'HISTORICAL RENT RECORDS': '历史租金记录',
    'RENT ARREARS NOTIFICATION': '租金漏缴提示',
    'RENT INCOME CASH FLOW': '租金收入现金流',
    'UPLOADING HEIC FORMAT FILES WILL AUTOMATICALLY CONVERT THEM TO JPG FORMAT IMAGES. EACH IMAGE TAKES ABOUT 2 SECONDS, SO PLEASE BE PATIENT.':'上传heic格式文件会自动进行转换为jpg格式图片， 每张图片大概需要2秒，请耐心等待。',
    'HELLO {EMAIL}, THE VERIFICATION CODE HAS BEEN SENT TO YOUR EMAIL, PLEASE FILL IN THE VERIFICATION CODE': '您好 {EMAIL}, 验证码已发送至您的邮箱，请填写验证码',
    'HELLO ': '您好',
    'WE SENT YOU A CODE AT ': '我们向您的邮箱',
    '. PLEASE CHECK AND ENTER THE CODE HERE TO CONTINUE SIGNING UP.': '发送了验证码，请在此处填写验证码以继续注册',
    LOGOUT: "退出登录",
    'MINI GOLF RANGE': '迷你高尔夫球场',
    'DRIVING RANGE': '练习场',
    REGISTER: "注册",
    'MINI-MART': '小超市',
    "PLEASE INPUT CODE": "请填写验证码",
    "WRONG EMAIL FORMAT": "错误邮箱格式",
    "PLANNING AREA": '区域',
    RENT: "租房",
    "_RENT": '租金',
    "SECURITY SETTINGS": "安全设置",
    EDIT: "编辑",
    "CHANGE AVATAR": "更换头像",
    FILTER: "筛选",
    "SHOW LESS": "收起",
    "SHOW MORE": "展开",
    'AREA - HIGH TO LOW': '面积 - 高到低',
    'AREA - LOW TO HIGH': '面积 - 低到高',
    'PRICE - HIGH TO LOW': '价格 - 高到低',
    'PRICE - LOW TO HIGH': '价格 - 低到高',
    PROPERTY: "房产",
    "CUSTOMER SERVICE": "客服",
    "OVERVIEW": "总览",
    BEDROOM: "卧室数量",
    PRICE: "价格",
    SORT: "排序",
    AREA: "面积",
    "HIGH TO LOW": "高到低",
    "LOW TO HIGH": "低到高",
    "LINK": "绑定",
    "LINK WECHAT": "绑定微信",
    "LINK WHATSAPP": "绑定WhatsApp",
    LATEST: "最新",
    'THE MAXIMUM PRICE IS NOT ALLOWED TO BE LESS THAN THE MINIMUM PRICE': "最高价不允许小于最低价",
    "MOST ECONOMICAL": "最划算",
    "LESS THAN": "小于",
    MIN: "分钟",
    "_MIN": "最小",
    COMMUTE: "公交时间",
    "SEE DETAIL": "查看详情",
    SHARE: "分享",
    "ALL FEMALE": "全女生环境",
    "ALL MALE": "全男生",
    MIX: "混合",
    UNSET: "不限",
    'CREATOR': '创建人',
    'CONTACT': '电话',
    'URL HAS BEEN COPIED TO THE PASTEBOARD': '链接已复制',
    GENDER: "性别",
    'YOUR BROWSER DOES NOT SUPPORT CLIPBOARD API': '您的浏览器不支持复制功能',
    "MOVE IN DATE": "入住日期",
    "MORE DETAILS": "详情",
    "ABOUT THIS PROPERTY": "关于房屋",
    "RENTAL TYPE": "房屋类型",
    'CONTACT ME':'联系我',  
    "UNIT SIZE": "房屋面积",
    'DETAILS': '详情',
    'FILTER LIST': '筛选列表',
    "MOVE IN": "入住日期",
    TENURE: "使用权",
    'APPLY': '应用',
    'DOCUMENT TYPE': '证件类型',
    'DOCUMENT NUMBER': '证件号码',
    "TOP YEAR": "竣工时间",
    "UNIT INFO": "房屋信息",
    DEVELOPER: "开发商",
    FURNISHING: "装修",
    "SURROUDING": "周边",
    "FLOOR LEVEL": "楼层",
    "TOTAL UNITS": "总户数",
    "CURRENT TENANTS": "目前租户",
    SHOPPING: "商场",
    'ADULT FITNESS STATIONS': '成人健身站',
    'CAFES': '咖啡馆',
    SCHOOLS: "教育机构",
    'MEETING ROOM': '会议室',
    "UPDATE WHATSAPP SUCCESS": "更新Whatsapp账号成功",
    "CONDO FACILITIES": "公寓设施",
    'UPLOAD COVER': '上传封面',
    'CHANGE COVER': '更换封面',
    "SWIMMING POOL": "游泳池",
    'WECHAT QRCODE URL': '微信二维码',
    'DESCRIPTION ZH': '介绍(中文)',
    'CREATOR INFORMATION': '创建者信息',
    'COMPLETION YEAR': '竣工时间',
    'MAIN ENTRANCE': '主入口',
    'POSTCODES': '邮编',
    "BARBEQUE AREA": "烧烤区",
    'CHILDRENS POOL': '儿童泳池',
    'SHELTERS': '庇护所',
    "CHILDREN'S PLAYGROUND": '儿童游乐园',
    "COVERED CAR PARK": "停车场",
    'REFLEXOLOGY PATH': '足底按摩步道',
    'AIRCON FACILITIES': '空调',
    'AMPHITHEATRE': '露天剧场',
    'COVERED LINKWAYS': '有盖通道',
    "CAR PARK": "停车场",
    'SKYLINE TERRACE': '天际线露台',
    "LIVING ROOM FURNITURE": "客厅家具",
    'SQUASH COURT': '壁球场',
    SAUNA: "桑拿",
    "GYMNASIUM ROOM": "健身房",
    "24 HOURS SECURITY": "全天安保",
    "FITNESS CORNER": "室外健身器材",
    CLUBHOUSE: "俱乐部",
    'IT SEEMS THAT THE SAME POSTCODE ALREADY EXISTS. DO YOU WANT TO VIEW OR CONTINUE CREATING?': '似乎已有相同邮编，您想要查看还是继续创建？',
    "COPY LINK": '复制链接',
    "GALLERY": '图库',
    'CREATED SUCCESSFULLY, YOU CAN DIRECTLY ACCESS THE DETAILS PAGE. THERE IS A DELAY IN UPLOADING PICTURES AND VIDEOS, PLEASE WAIT PATIENTLY...': '创建成功，你可以直接访问详情页，图片和视频上传有延迟，请耐心等待...',
    "_LINK": '链接',
    'READY TO MOVE-IN': '马上入住',
    "CUSTOMER INFORMATION": '客服信息',
    "WITHIN 2 MONTHS": '2 个月内入住',
    "WITHIN 1 MONTH": '1个月内入住',
    "WITHIN 1 WEEK": '1 周内入住',
    'ANY DATE': '任意时期',
    "FUNCTION ROOM": "多功能房",
    JACUZZI: "按摩浴缸",
    PLAYGROUND: "操场",
    'DETAIL': '详情',
    "SPA POOL": "水疗池",
    "WADING POOL": "儿童泳池",
    "TENNIS COURTS": "网球场",
    'PARTY LAWN': '草坪派对',
    'CO-WORKING SPACE': '联合办公区域',
    'GARDEN TERRACE': '露台花园',
    "OPEN CAR PARK": "露天停车场",
    "BASEMENT CAR PARK": "地下停车场",
    "FILE SIZE EXCEEDS THE LIMIT": "文件大小超过限制",
    'PET CORNER': '宠物角',
    LIBRARY: "图书馆",
    "PUTTING GREEN": "果岭",
    'POOLSIDE LOUNGE': '池畔酒廊',
    'TENANT': '租客',
    'AGENT': '中介',
    'LANDLORD': '房东',
    'COMPANY': '公司',
    'ADMIN': '管理员',
    'LISTING DETAIL': '广告详情',
    PAVILLION: "展览馆",
    "WIFI": "无线网络",
    'UNIT DRAFT': '房屋草稿',
    "HYDROTHERAPY POOL": "水疗池",
    "ADMIN PORTAL": "管理后台",
    'COMMUNITY GARDEN': '社区花园',
    'SKY LOUNGE': '空中酒廊',
    'PLEASE FILL IN THE REQUIRED FIELDS MARKED WITH *': '请填写 带 * 的必填项',
    "IF NOTHING IS UPLOADED, THE UNIT OR ROOM'S COVER WILL BE USED AS THE ADVERTISEMENT COVER BY DEFAULT.": "如果没有上传任何图片，房屋或房间的封面将作为默认广告封面。",
    "STUDY ROOM": "学习室",
    "VISIBLE ONLY TO YOU, NOT VISIBLE TO OTHERS.": "仅对您可见，对其他人不可见。",
    "MULTI-PURPOSE HALL": "多功能礼堂",
    "SELECT THE LANGUAGE TO DESCRIBE THE INFORMATION AND CLICK 'TRANSLATION' TO AUTOMATICALLY TRANSLATE IT INTO ANOTHER LANGUAGE.": "选择描述信息的语言,点击'翻译'按钮后则会自动翻译成另一个语言",
    "STEAM ROOM": "蒸汽浴室",
    "FUN POOL": "趣味泳池",
    "USERS": "用户管理",
    MORE: "更多",
    'TRANSLATE': '翻译',
    'DOCUMENT IMAGE': '证件照片',
    DELETE: "删除",
    'INFORMATION EXTRACTION FAILED': '信息提取失败',
    SEARCH: "搜索",
    'SEARCH UNIT BY UUID OR POSTCODE': "根据UUID或邮编搜索房屋",
    "PERMISSION MANAGE": "权限管理",
    "BATCH DELETION": "批量删除",
    "ADD USER": "新增用户",
    "MODIFY PERMISSIONS": "权限设置",
    MODIFY: "修改",
    "USER SELECTION": "用户选择",
    "ROLES SELECTION": "角色选择",
    "MY LISTINGS": "广告管理",
    "ROLES": "身份",
    "ADD NEW": "新增",
    "EXT NAME": "额外信息名称",
    'LONGITUDE': '经度',
    'POSTCODE LENGTH CANNNOT BE LESS THAN 6': '邮编长度不可以小于6',
    "LATITUDE": "纬度",
    'TYPE IN YOUR EMAIL TO RECEIVE A PASSCODE': '输入您的邮箱以获取验证码',
    "EXT VALUE": "额外信息值",
    "Y": "是",
    "DISTANCE": "距离",
    "OFFICIAL IMAGES": '官方图片',
    'OFFICIAL VIDEOS': '官方视频',
    "MEDIA": "图片",
    'VIDEOS': '视频',
    "BACK": "返回",
    'CONTINUE': '继续',
    'VIEW': '查看',
    'PROJECT INFORMATION': '小区信息',
    "DISTRICT": '行政区',
    'LOG IN OR SIGN UP': '登录或注册',
    'CS INFORMATION': '客服信息',
    'BY UPLOAD': '文件上传',
    'BY URL': '链接上传',
    'CONTACT INFORMATION': '联系方式',
    'VIDEO URLS': '视频链接',
    'PLEASE WAIT {COUNT} SECONDS': '请等待{COUNT}秒',
    "TERMS TEXT": "我同意 HomeJoy 的{TERMS}、{ACCEPTABLEUSE}和{PRIVACYPOLICY}，包括对我的个人信息的收集、使用、处理、存储和披露，以及对我使用网站的相关规定。{ACCOUNT}",
    "TERMS OF SERVICE": "服务条款",
    "ACCEPTABLE USE POLICY": "可接受使用政策",
    "ACCOUNT APPEAL": "账户申诉",
    "PRIVACY POLICY": "隐私政策",
    'VIDEO FILES': '视频文件',
    'ALL UPLOADED DATA REMAINS THE PROPERTY OF YOUR COMPANY. BY UPLOADING DATA, YOUR COMPANY GRANTS THIS PLATFORM THE RIGHT TO USE IT AS NEEDED. YOUR COMPANY IS SOLELY RESPONSIBLE FOR ENSURING THE LEGAL COMPLIANCE OF THE DATA IT UPLOADS.': '所有上传的数据归贵公司所有。贵公司同意授予本平台使用所上传数据的权利，同时贵公司需对其上传数据的合法性承担责任。',
    "OUR PLATFORM IS RESPONSIBLE FOR PROVIDING YOUR COMPANY'S ROOM INFORMATION TO POTENTIAL TENANTS, WHILE THE LEGAL DETAILS OF THE CONTRACT ARE THE RESPONSIBILITY OF THE CO-LIVING COMPANY, WHICH MAY CHOOSE TO DELEGATE THIS TO A CEA LICENCED REAL ESTATE AGENT.": "本平台的大部分基础服务均为免费。对于任何需要收费的服务，我们会在产生费用前与贵公司提前沟通。",
    'MOST BASIC SERVICES ON THIS PLATFORM ARE FREE OF CHARGE. FOR ANY SERVICES THAT INCUR A FEE, WE WILL COMMUNICATE WITH YOU IN ADVANCE BEFORE ANY CHARGES ARE APPLIED.': "本平台负责将贵公司的房间信息提供给潜在租客，合同的法律细节由合租公司自行负责，合租公司可委托房产经纪人处理。",
    'LAND SIZE': '土地面积',
    'MODIFY USER': '修改用户',
    'DELETE USER': '删除用户',
    'UPDATE POI SUCCESS': '更新Poi成功',
    'ENTER YOUR SCHOOL/CONDO/DISTRICT/': '请输入你的学校名称/Condo名称/区域名',
    "N": "否",
    //'': '创建广告成功！是否继续创建？',
    //'': '表单验证失败',
    ADVERTISE: "发广告",
    'TYPE': '类型',
    'PLEASE VERIFY DOCUMENT INFORMATION': '请验证证件信息',
    'VERIFICATION SUCCESSED': '验证通过',
    'VERIFICATION FAILED': '验证未通过',
    'PASS EXPIRE DATE': '证件过期日期',
    "CREATE ROOM": "创建房间",
    'VERIFYING...': '验证中...',
    "OFF SHELVES": "下架",
    'MY RENTAL': '我的租赁',
    
    "ADD LISTING": "上传广告",
    'PASS EXPIRY DATE': '证件过期日期',
    'PLEASE ENSURE THAT THE FOLLOWING INFORMATION HAS BEEN FILLED IN: FULL NAME, DATE OF BIRTH, PASS EXPIRY DATE, PASS TYPE, FIN': '请确保已填写以下信息：姓名、出生日期、证件过期日期、证件类型、FIN',
    BACKSTAGE: "后台管理",
    DASHBOARD: "仪表盘",
    '_UNSET': '未设置',
    'MEMBER LIST': '成员列表',
    'VIDEO UPLOAD': '视频上传',
    "CREATE SUCCESS": "创建成功！",
    "EDIT LISTING": "编辑广告",
    "NO SET": "不设置",
    CURRENCY: "货币",
    "STATUS": "状态",
    "ROOM": '房间',
    "PENDING": "审批中",
    "REJECTED": '已驳回',
    "PUBLISHED": "已发布",
    'WATING LOADING': '创建{PROPERTY_TYPE}中...请勿刷新页面',
    'POIS': 'POI管理',
    "ADD POI": 'POI上传',
    "APPROVED": '已批准',
    "FAILED": "审批不通过",
    "UNPUBLISHED": "未发布",
    "DELISTED": "已强制下架",
    "ARCHIVED": "已归档",
    "PRICE MIN": "月租底价",
    "PRICE NORMAL": "月租价格",
    "LISTING TYPE": "类型",
    'PLEASE INPUT UNIT AREA': '请输入房屋面积',
    'COOKING ALLOWED': '是否允许做饭',
    POSTCODE: "邮编",
    "ROOM TYPE": "房间类型",
    "MASTER ROOM": "主人房",
    "COMMON ROOM": "客房",
    "MAID ROOM": "佣人房",
    "PARTITION ROOM": "隔间",
    DEPOSIT: "押金",
    "APPLY FILTER": "应用筛选",
    "MAX": "最大",
    "THE UPLOADED FILE CANNOT BE LARGER THAN 5M": "上传文件不允许超过5M",
    "UNIT NO": "门牌号",
    "WIFI CHARGE": "网络费用",
    "POSTCODE IS REQUIRED": "邮编为必填项",
    "POSTCODE MUST BE EXACTLY 6 DIGITS": "邮编必须为6位数字",
    "NAME REQUIRED": "姓名为必填",
    "RENT AND PAYMENT LINK REQUIRED": "租金跟支付链接需要填写",
    "RENT REQUIRED": "租金需要填写",
    "PLEASE INPUT LISTING TYPE": "广告类型为必填项",
    "MAXIMUM CHARACTER LENGTH IS": "最大字符限制",
    "INCORRECT POSTCODE": "无效邮编",
    "PROJECT NAME": "小区名称",
    "ROOM PHOTOS/VIDEO": "房间 照片/视频",
    "UNIT PHOTOS/VIDEO": "房屋 照片/视频",
    "CONDO PHOTOS/VIDEO": "小区 照片/视频",
    "SURROUNDING PHOTOS/VIDEO": "周边 照片/视频",
    "LAYOUT PHOTOS": "房屋结构图",
    "DELETE SUCCESS": "删除成功",
    "DELETE FAIL": "删除失败",
    OPTIONAL: "可选",
    "IS ACTIVE": "是否激活",
    "UNIT RENTAL": "整租",
    "ROOM RENTAL": "合租",
    "DEPOSIT DESCRIPTION": "押金描述",
    'ARE YOU SURE TO DELETE THIS COMPANY': '确定删除该公司吗',
    "PERSONAL CENTER": "个人中心",
    "PERSON PROFILE": "个人简介",
    'BECOME LANDLORD': '成为房东',
    "BASIC INFORMATION": "基本设置",
    "ACCOUNT BINDING": "账户绑定",
    EATERIES: "食阁",
    'VISIT WEBSITE': '访问网站',
    '_OPERATION': '运营数据',
    "HIGHER EDUCTATION": "热点学校",
    'COMPANIES': '公司管理',
    'ARE YOU SURE TO DELETE THIS COMPANY?': '确定删除该公司吗？',
    'COMPANY HOMEPAGE BROWSING': '公司主页浏览',
    APARTMENT: "apartment",
    HDB: "HDB",
    'ARTICLE CONTENT': '文章内容',
    'LAST UPDATED': '最后编辑于',
    "PREVIEW": '预览',
    'LOCATION': '位置',
    'ARTICLE INDEX NAME': '文章索引名称',
    'USED FOR HOMEPAGE DISPLAY AND URL PATH. IF NOT FILLED IN, THE ARTICLE TITLE WILL BE USED BY DEFAULT.': '用于首页显示和url路径,如不填则默认使用文章标题',
    'FILL IN THE CHINESE TITLE OF THE ARTICLE. IF NOT FILLED IN, IT WILL BE AUTOMATICALLY TRANSLATED.': '填写文章中文标题，如果不填写则自动翻译',
    LANDED: "landed",
    "ARTICLE TITLE": "文章标题",
    "IS VISIBLE": "是否可见",
    DESCRIPTION: "介绍",
    "PLEASE INPUT UNIT LEVEL": "房屋楼层为必填项",
    "PLEASE INPUT UNIT STACK": '房屋房间号为必填项',
    LEVEL: "楼层",
    STACK: "房间号",
    'ARTICLE TITLE(ZH)': '文章中文标题',
    "MRT STATIONS": "地铁站",
    "MRT STATIONS NAME": "地铁站名称",
    "MRT STATION CAN NOT SET BE NULL": "地铁站不能设置为空",
    "BUS STATION CAN NOT SET BE NULL": "公交站不能设置为空",
    "BUS STOP NAME": "巴士站名称",
    "MY UNITS": "房屋管理",
    "ADD UNIT": "房屋上传",
    "ADD ROOM": "房间上传",
    "APPLICANT NAME": '申请人姓名',
    "APPLICANT EMAIL": '申请人邮箱',
    "REGISTER AND LOGIN": '注册并登录',
    'ROOM LABEL NOTIFICATION': "房间标签非常重要，因为它标识了租房合同中的具体房间。示例包括“'Master Bedroom,' 'Common Room 1'/'CR1', 'Balcony Room,' 或如“Solar”这样的独特名称。注意: 此处只接受英文。",
    "APPLICANT WECHAT ID": '申请人微信账号',
    "APPLICANT WECHAT QR CODE": '申请人微信二维码',
    'APPROVALS': '审批管理',
    'APPROVAL': '批准',
    'REFUTE': '驳回',
    'MY BUSINESS': '我的公司',
    'THANK YOU!': '谢谢！',
    'NO FILE UPLOAD': '未上传文件',
    'APPROVAL SUCCESS': '审批成功',
    'YOUR SUBMISSION WAS SUCCESSFUL. OUR CUSTOMER SERVICE TEAM WILL COMPLETE THE APPROVAL PROCESS AS SOON AS POSSIBLE. THIS MAY TAKE 1-2 WORKING DAYS, PLEASE BE PATIENT.': '您的提交已成功。我们的客服团队将尽快完成审批流程。这可能需要1-2个工作日，请耐心等待。',
    'APPROVAL FAILED': '审批失败',
    'REJECT': '驳回',
    'MESSAGE': '信息',
    'DOWNLOAD': '下载',
    'CREATE TENANCY': '创建租约',
    'ADD TENANT': '添加租客',
    'BIRTH DATE': '出生日期',
    'RENTAL REMINDER': '催租提醒',
    'RENT REMINDER DATE': '催租提醒日',
    'PAYMENT LINK': '支付链接',
    'GENERATE REMINDER TEMPLATE': '生成催租模板',
    'RENT REMINDER INFO TEMPLATE': '催租信息模板',
    'RENT REMINDER INFO' : '催租信息',
    'GENERATED RENT REMINDER INFO': '生成催租信息',
    'BASIC TEMPLATE': '基础模板',
    'TEMPLATE 1': '模板1',
    'TEMPLATE 2': '模板2',
    'CUSTOM TEMPLATE': '自定义模板',
    "CREATED BY": "创建者",
    'IDENTITY TYPE': '身份证类型',
    'FIN TYPE': '签证类型',
    'WEEKLY PASS CHECK': '答证每周自动检查',
    'IDENTITY NUMBER': '身份证号码',
    'DETECTION IDENTITY NUMBER': '检测身份证号码',
    PASSPORT: "护照",
    'COUNTRY CODE': '区号',
    'LANDLORD APPROVAL': '房东申请审批',
    'SETTINGS': '设置',
    'DELETE COMPANY SUCCESS': '删除公司成功',
    'ACTIVE': '已激活',
    'REFUTED': '已驳回',
    'UPLOAD COMPANY LOGO': '上传公司LOGO',
    'SEARCH COMPANY NAME': '搜索公司名称',
    'EXCEED FILE LIMIT': '超过文件限制, 最大文件数量为{NUM}',
    "UPDATE TIME": "更新时间",
    "CREATE TIME": "创建时间",
    USERNAME: "用户名",
    'MONTH': '月',
    'TENANTS': '租客管理',
    "UTILITY CHARGE": "水电费",
    "MIN LEASE TERM": "最短租期",
    "MIN LEASE TERM(MONTHS)": "最短租期（月）",
    AVAILABILITY: "可入住日期",
    "AVAILABLE NOW": "现在是否可入住",
    "GENDER PORTFOLIO": "租客性别环境",
    "LIVE WITH LANDLORD": "是否与房东同住",
    ADDRESS: "地址",
    UNDEFINED: "不设置",
    'PROPERTY TYPE': '房源类型',
    BEDROOMS: "卧室数量",
    BATHROOMS: "卫生间数量",
    'ROOMS': '房间',
    'ROOMS LIST': '房间列表',
    "AREA SQFT": "面积（平方英尺）",
    "AREA SQM": "面积（平方米）",
    'ANALYSIS': '分析',
    "AREA UNIT": "面积单位",
    "BUS STOPS": "巴士站",
    'COMPANY NAME': '公司名称',
    'COMPANY POSITION': '公司职位',
    "ROOM ID": "房间ID",
    'COMPANY EMAIL': '公司邮箱',
    'COMPANY PHONE NUMBER': '公司电话',
    'USER': '租客',
    'DISABLE USER': '停用账号',
    'COMPANY APPROVAL': '公司申请审批',
    'ENABLE USER': '启用账号',
    'DISABLE': '已禁用',
    'ENABLE': '已启用',
    'GLOBAL_CS': '超级客服',
    'ROLE': '角色',
    'SUPERUSER': '超级管理员',
    "PRICE DESCRIPTION": "价格描述",
    'DISPLAY USER': '是否展示创建者',
    'COVER IMAGE': '封面图片',
    'PLEASE SELECT A UNIT': '请选择一个房屋',
    "PLEASE SELECT A ROOM": "请选择一个房间",
    "PHOTO UPLOAD SUCCESS": "图片上传成功",
    "PHOTO UPLOAD FAIL": "图片上传失败",
    "VIDEO UPLOAD SUCCESS": "视频上传成功",
    "VIDEO UPLOAD FAIL": "视频上传失败",
    'PHOTOS URL UPLOAD': '图片链接上传',
    "FORCE UPLOAD": "强制上传",
    'SELECT ROOM': '选择房间',
    'PHOTO URL': '图片链接',
    'SELECT UNIT': '选择房屋',
    'IMAGE VERIFICATION FAILED, DO YOU WANT TO FORCE UPLOAD?': "图片验证失败，是否强制上传？",
    'VIDEO VERIFICATION FAILED, DO YOU WANT TO FORCE UPLOAD?': "视频验证失败，是否强制上传？",
    'UPLOADING...': '上传中...',
    'VIDEO URL': '视频链接',
    "ROOM LABEL": "房间标签",
    "BED SIZE": "床尺寸",
    "DELETE ROOM": "删除房间",
    'NO INTERFACE ADDRESS CONFIGURED': '未配置接口地址',
    'URL UPLOAD': 'URL上传',
    '_NAME': '姓名',
    "HAS AC": "有空调",
    "HAS DESK": "有桌子",
    "HAS WARDROBE": "有衣柜",
    "HAS WINDOW": "有窗户",
    "NOISE LEVEL": "噪音等级",
    '{NUM} FILES SELECT': '已选择{NUM}个文件',
    OPERATION: "操作",
    "VERY QUIET": "非常安静",
    "QUIET": "安静",
    "FACING POOL": "面向泳池",
    "FACING SMALL ROAD": "面向小路",
    "FACING EXPRESSWAY": "",
    "NEAR CONSTRUCTION SITE": "",
    SUBMIT: "提交",
    ADD: "新建",
    "HOMES FOR RENT IN SINGAPORE": "个新加坡房屋信息",
    EXT: "额外",
    BLOCK: "栋",
    "ARE YOU SURE TO DELETE THIS UNIT": "您确定删除该房屋信息？",
    YES: "是",
    NO: "否",
    "CLEAR ALL FILTERS": "清除筛选",
    SQFT: "平方英尺",
    SQM: "平方米",
    "CANNOT BE ADDED REPEATEDLY": "不可重复添加",
    "MANAGE PICTURE": "照片管理",
    "PLEASE INPUT ": "请输入",
    "PICK AVAILABILITY DAY": "选择入住日期",
    "E.G.": "例如：",
    "PLEASE INPUT FORMAT": "请按该格式填写：",
    "ADD BUS STOP": "添加巴士站",
    "ADD MRT STATION": "添加地铁站",
    M: "米",
    'CHINESE': '中文',
    'ENGLISH': '英文',
    'CURRENT RENTAL': '当前租房',
    'ROOM PASSWORD': '房间密码',
    'CREATE PASSWORD': '创建密码',
    'DELETE PASSWORD': '删除密码',
    'PAY RENT': '支付房租',
    'CONTRACT': '我的合同',
    MO: "月",
    "LISTING DESCRIPTION": "广告描述",
    "NO DESCRIPTION OF THIS HOUSE HAS BEEN RELEASED YET": "暂时未发布该房屋相关描述",
    "SELECT LANGUAGE": "选择语种",
    "LOGIN SUCCESS": "登录成功",
    'FIRST NAME': '名字',
    'LAST NAME': '姓氏',
    'FULL NAME': '全名',
    "INVALID VERIFICATION CODE": "验证码错误",
    "REGISTRITION SUCCESS": "注册成功",
    "REGISTRITION FAILURE": "注册失败",
    "TO CAMPAIGN PAGE": "前往活动页面",
    "DETECT WECHAT": "检测WeChat账户",
    'CANNOT DISABLE YOUR OWN ACCOUNT': '不能停用自己的账号',
    "DETECT WHATSAPP": "检测Whatsapp账户",
    'DISABLED ACCOUNT': '已停用账号',
    'ENABLED ACCOUNT': '已启用账号',
    BEDROOM_1: "室",
    'BELOW': '低于S$ {price}',
    "ABOVE": '高于S$ {price}',
    'I AGREE TO THE ABOVE TERMS': "我同意以上条款",
    BATHROOM_1: "卫",
    'USER AGREEMENT': '用户协议',
    'CHECK ACCOUNT INFORMATION': '检测账户信息',
    'GO TO SETTING': '前往设置',
    "UPDATE POI FAILED": '更新Poi失败',
    'COMPLETE': '完成',
    'CONGRATULATIONS, YOU HAVE COMPLETED THE ENTIRE PROCESS OF BECOMING A LANDLORD.': '恭喜您，您已经完成了成为房东的整个过程。',
    'BECOME HOST': "成为房东",
    'PREVIOUS STEP': '上一步',
    "RELATED PROJECT": '关联小区',
    'REAL NAME': '真实姓名',
    'NEXT STEP': "下一步",
    'AFFILIATED COMMUNITY': '所属小区',
    'CHANGE': '更变',
    'COMPLETED': '已完成',
    "CUSTOMER SERVICE NAME CANNOT BE EMPTY": '客服名称不可以为空',
    'CS DISPLAY NAME': '客服姓名',
    'GUIDELINES FOR BECOMING A LANDLORD': "成为房东指南",
    'YOUR REQUEST HAS BEEN SUBMITTED TO CUSTOMER SERVICE, AND THEY WILL RESPOND WITHIN 1-2 BUSINESS DAYS.': "您的请求已提交给客服，他们将在 1-2 个工作日内回复。",
    'ARE YOU SURE TO DELETE THIS LISTING?': "您确认删除该广告吗？",
    'CONGRATULATIONS, YOU HAVE BECOME A LANDLORD!': "恭喜你，你成为房东了！",
    'SORRY, THE APPLICATION PROCESS IS CURRENTLY NOT OPEN.': '抱歉，目前尚未开放申请流程',
    "VIEW IMAGE": "查看图片",
    'YOU ARE NOT CURRENTLY A LANDLORD, BUT BECOMING ONE ONLY TAKES A FEW STEPS.': "您目前还不是房东，但只需几个步骤即可成为房东。",
    "WECHAT QR": "微信二维码",
    "WECHAT ACCOUNT": "微信号",
    PUBLISH: "发布",
    "FORCE OFF SHELVES": "强制下架",
    "NAMING ROOM": "自己给的房间命名",
    "ADS INFO": "广告信息",
    "ROOM INFO": "房间信息",
    "YOUR FIRST UPLOADED IMAGE WILL BE DISPLAYED ON HOMEPAGE": "您上传的第一张图会被用于首页展示",
    "PREVIEW IMAGE": "预览图片",
    "MODIFY USER IDENTITY": "修改身份",
    'ARTICLE TITLE SHOULD BE LESS THAN 50 WORDS': "文章标题应小于50个字",
    "SEARCH USERNAME OR EMAIL": "请输入用户名或邮箱搜索",
    "ENTER NEW USER EMAIL": "请输入新用户邮箱",
    "ENTER NEW USER NICKNAME": "请输入新用户昵称",
    'PENDING REVIEW': '审核中',
    "UPLOAD AVATAR": "上传头像",
    'GENERALLY COMPLETED WITHIN 1-2 BUSINESS DAYS. THANK YOU FOR YOUR PATIENCE!': '一般1-2个工作日内完成，感谢耐心等待！',
    "YOU ALREADY HAVE A COMPANY, PLEASE DO NOT CREATE ANOTHER ONE.": "您已经有公司了，请勿重复创建.",
    'SUBMISSION SUCCESSFUL, CUSTOMER SERVICE WILL COMPLETE THE PROCESS APPROVAL AS SOON AS POSSIBLE. THIS PROCESS MAY TAKE 1-2 WORKING DAYS, PLEASE BE PATIENT.': '提交成功，客服将尽快完成审批流程。此流程可能需要1-2个工作日，请耐心等待。',
    "ENTER NEW USER PHONE NUMBER": "请输入新用户电话号",
    "SELECT NEW USER IDENTITY": "选择新用户身份",
    'SELECT FILE': "选择文件",
    'ARTICLE CONTENT(ZH)': '文章中文内容',
    OPTIONAL_1: "可选项",
    'BACKGROUND': '背景图片',
    'SUBMISSION SUCCESSFULLY': '提交成功',
    "ENTER NEW USER WECHAT ID": "请输入新用户微信号",
    "UPLOAD WECHAT QR CODE": "上传微信二维码",
    "ENTER REAL ESTATE LICENSE": "请输入地产职业证书",
    "ENTER FOREIGN SINGAPORE ID": "请输入外国人新加坡身份证号(FIN)",
    "ENTER LOCAL ID": "请输入本地身份的身份证号(NRIC)",
    "ENTER AGENT LICENSE": "请输入中介证书",
    "ENTER NEW USER WHATSAPP": "请输入新用户WhatsApp",
    "ENTER ID REAL NAME": "请输入身份证实名",
    "ENTER CUSTOMER SERVICE NAME": "请输入客服名",
    "EXISTING IDENTITIES": "已有身份",
    "ALL IDENTITIES": "所有身份",
    'CONGRATULATIONS, YOU MEET THE BASIC REQUIREMENTS FOR STARTING A COMPANY.': '恭喜您，您符合创建公司的基本要求',
    "WECHAT ID": "微信号",
    "PHONE": "电话号",
    'VISA STATUS': '签证状态',
    'CHECK AT': '检查时间',
    'LEASE FROM': '租赁开始时间',
    'LEASE TO': '租赁结束时间',
    'AGENCY LICENSE': '中介执照',
    'CEA NUMBER': '房地产代理理事会(CEA)证书编号',
    'NRIC': '新加坡身份证号(NRIC)',
    'CEA NO': '房地产代理理事会(CEA)证书编号',
    'ARE YOU SURE TO DELETE THIS USER?': '是否确认删除该用户？',
    'FIN': '外国人身份证号(FIN)',
    'SUCCESS': '成功',
    "UPDATE AVATAR SUCCESS": "更新头像成功",
    "UPDATE AVATAR FAILURE": "更新头像失败",
    "CHANGE PICTURE": "更换图片",
    "UPLOAD PICTURE": "上传图片",
    "BIND ID CARD": "绑定身份证",
    "ALREADY BOUND": "已绑定",
    "CURRENTLY UNBOUND WHATSAPP": "当前未绑定Whatsapp",
    "INCOMPLETE INFORMATION": "信息未填写完整",
    "CURRENTLY UNBOUND WECHAT": "当前未绑定WeChat",
    "RESET": "重置",
    "REMOVE": "移除",
    "_ADD": "添加",
    "IS WHITELIST": "是否为白名单",
    'LIFT LOBBY': '电梯大堂',
    'LEISURE POOL': '休闲泳池',
    'BBQ PAVILLION': '烧烤亭',
    'COMMUNITY PLAZA': '社区广场',
    'UPLOAD': '上传',
    'CHANGING ROOM': '更衣室',
    'BASKETBALL COURT': '篮球场',
    'KARAOKE': '卡拉OK',
    'LOUNGE': '休息室',
    'JOGGING TRACK': '慢跑道',
    'CHILDRENS PLAYGROUND': '儿童游乐场',
    'PANTRY': '食品储藏室',
    'RECREATION ROOM': '休闲室',
    'LAUNDERETTE': '自助洗衣店',
    'MULTI-STOREY CAR PARK': '多层停车场',
    'ADVENTURE PARK': '冒险乐园',
    "CHILDREN'S POOL": '儿童泳池',
    'BILLIARDS ROOM': '台球室',
    'COASTAL COURTYARD': '滨海庭院',
    'ROOF TERRACE': '屋顶露台',
    'DROP OFF POINT': '下车点',
    'AEROBIC POOL': '有氧池',
    'DANCE STUDIO': '舞蹈室',
    'ARTICLES': '文章管理',
    'UPLOAD ARTICLE': '上传文章',
    'ELECTRICAL SUB-STATION': '变电站',
    'ELDERLY WELLNESS STATIONS': '老人健康站',
    'STEAM BATH': '蒸气浴',
    'GAME ROOM': '游戏室',
    'UTILITIES CENTER': '公用事业中心',
    'EXISTING BUS STOP': '现有巴士站',
    'SKY GARDEN': '空中花园',
    'BADMINTON HALL': '羽毛球馆',
    'PRECINCT PAVILION': '专区展馆',
    'POOL DECK': '泳池甲板',
    'BOWLING ALLEY': '保龄球馆',
}
