import store from "@/store";

export const USER_ROLE = { // 角色
    SUPERUSER: "superuser", // 超级管理员
    LANDLORD: "landlord", // 房东
    USER: "user", // 普通用户
    GLOBAL_CS: "global_cs", // 全局客服,
    COMPANY: 'company', // 公司
    // CUSTOMER_SERVICE: "customer service", // 客服
}

USER_ROLE.ALL = [
    USER_ROLE.SUPERUSER,
    USER_ROLE.LANDLORD,
    USER_ROLE.USER,
    USER_ROLE.GLOBAL_CS,
    // USER_ROLE.CUSTOMER_SERVICE,
]; // 所有角色

USER_ROLE.LESSOR = [
    USER_ROLE.SUPERUSER,
    USER_ROLE.LANDLORD,
    USER_ROLE.COMPANY,
] // 房东角色

USER_ROLE.ADMIN = [
    USER_ROLE.SUPERUSER,
    USER_ROLE.GLOBAL_CS,
]; // 管理员角色

export function PermissionCheck(roles) {
    if (!store.state.user.isLogin) {
        return false
    }

    const set1 = new Set(roles)
    const set2 = new Set(store.state.user.userInfo.roles)

    return [...set1].filter(value => set2.has(value)).length > 0
}

export function IsCustomerService(){
    return PermissionCheck([USER_ROLE.CUSTOMER_SERVICE, USER_ROLE.GLOBAL_CS])
}
