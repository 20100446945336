import requests from "./requests";
import { ElMessage } from "element-plus";
import qs from 'qs'

class Request {
    #callback = (res) => {

    }

    #err_callback = (err) => {

    }

    post(url, args, cb = this.#callback, err_cb = this.#err_callback) {
        this.#request(url, 'post', args, cb, err_cb)
    }

    get(url, args, cb = this.#callback, err_cb = this.#err_callback) {
        var param = {}

        for (var i in args["params"]) {
            if (args["params"][i] instanceof Array) {
                param[i] = args["params"][i]
                delete args["params"][i]
            }
        }

        if (Object.keys(param).length > 0) {  // 判断是否有参数
            url = url + "?" + qs.stringify(param, { arrayFormat: 'repeat' })
        }
        return this.#request(url, 'get', args, cb, err_cb)
    }


    delete(url, args, cb = this.#callback, err_cb = this.#err_callback) {
        this.#request(url, 'delete', args, cb, err_cb)
    }

    patch(url, args, cb = this.#callback, err_cb = this.#err_callback) {
        this.#request(url, 'patch', args, cb, err_cb)
    }

    put(url, args, cb = this.#callback, err_cb = this.#err_callback) {
        this.#request(url, 'put', args, cb, err_cb)
    }

    async async_request(url, method, args, cb = null, err_cb = null) {
        let { params: originalParams = {} } = args;
        let param = {};
        let params = {};
        for (let [key, value] of Object.entries(originalParams)) {
            if (Array.isArray(value)) {
                param[key] = value;
            } else {
                params[key] = value;
            }
        }

        if (Object.keys(param).length > 0) {  // 判断是否有参数
            url = url + "?" + qs.stringify(param, { arrayFormat: 'repeat' })
        }
        try {
            let response = await requests({...args,
                method: method,
                Headers: { "Content-Type": "multipart/form-data" },
                url: url,
                params: params
            })
            if (cb != null) {
                cb(response)
            }
            return response
        } catch {
            if (err_cb != null) {
                err_cb()
            }
        }
    }

    fetcher(config){
        return requests(config)
    }

    #request(url, method, args, cb, err_cb) {
        return requests(Object.assign({
            method: method,
            Headers: { "Content-Type": "multipart/form-data" },
            url: url,
        }, args)).then(cb).catch(err_cb);
    }

    
}
var api = new Request()
export default api