import requests from "@/request/requests"
import { ElMessage, genFileId } from 'element-plus'
import { server_protocol, server_host, server_port, common_response_code } from "@/setting/settings"
import qs from 'qs'
import store from "@/store";
import Cookies from 'js-cookie'
import router from "@/router";
import { computed, ref, onMounted, onUnmounted } from 'vue'
import { useClipboard } from "@vueuse/core";
const { copy } = useClipboard();

export function transform_to_url(array) {
    return array.map((el) => {
        return server_protocol + server_host + ":" + server_port + el;
    });
}

export function clean_account_info() {
    store.commit("setUserState", false)
    store.commit("setUserInfo", {});
}

export function clean_token_info() {
    Cookies.remove("token")
    Cookies.remove("refresh")
}

export const generateVideoThumbnail = (file = "", url = "") => {
    var src;
    if (file) {
        src = URL.createObjectURL(file);
    } else if (url) {
        src = url;
    } else {
        return null;
    }
    return new Promise((resolve, reject) => {
        const video = document.createElement("video");
        video.src = src;
        video.muted = true; // 静音播放以便能够播放视频
        video.autoplay = true;
        video.crossOrigin = "anonymous";
        video.style.display = "none";
        document.body.appendChild(video);

        video.addEventListener("loadeddata", () => {
            // 等待视频帧加载
            const canvas = document.createElement("canvas");
            canvas.width = video.videoWidth;
            canvas.height = video.videoHeight;
            const ctx = canvas.getContext("2d");
            ctx.drawImage(video, 0, 0, canvas.width, canvas.height);
            canvas.toBlob((blob) => {
                resolve(URL.createObjectURL(blob));
            }, "image/jpeg");
            // 增加延迟以确保视频帧加载
        });

        video.addEventListener("error", (e) => {
            reject(e);
        });
    });
};

export function copy_content(content, success_msg = '复制成功', err_msg = '复制失败') {
    try {
        copy(content);
        ElMessage.success(success_msg);
    } catch (err) {
        ElMessage.error(err_msg);
    }
}

export function AllowEnterNumber(value) {
    return value.replace(/\D/g, '');
}

export const isObjectEmpty = (obj) => {
    return Object.keys(obj).length === 0;
};

export const Default_Point = [
    {
        lat: 1.30169,
        lng: 103.83833,
        name: "AGI",
        address: "228 Orchard Rd, #228, Singapore 238853",
        type: "school",
    },
    {
        lat: 1.28828,
        lng: 103.77944,
        name: "Curtin",
        address: "10 Science Park Road, Singapore 117684",
        type: "school",
    },
    {
        lat: 1.32808,
        lng: 103.84574,
        name: "EASB",
        address: "9 Ah Hood Rd, Singapore 329975",
        type: "school",
    },
    {
        lat: 1.30006,
        lng: 103.78644,
        name: "INSEAD",
        address: "1 Ayer Rajah Ave, Singapore 138676",
        type: "school",
    },
    {
        lat: 1.30289,
        lng: 103.7877,
        name: "ESSEC",
        address: "5 Nepal Park, Singapore 139408",
        type: "school",
    },
    {
        lat: 1.31587,
        lng: 103.87581,
        name: "JCU",
        address: "149 Sims Dr, Singapore 387380",
        type: "school",
    },
    {
        lat: 1.30229,
        lng: 103.84961,
        name: "KAPLAN",
        address: "8 Wilkie Rd, Singapore 228095",
        type: "school",
    },
    {
        lat: 1.29698,
        lng: 103.80115,
        name: "MDIS",
        address: "501 Stirling Rd, Singapore 148951",
        type: "school",
    },
    {
        lat: 1.29987,
        lng: 103.85147,
        name: "NAFA",
        address: "80 Bencoolen St, Singapore 189655",
        type: "school",
    },
    {
        lat: 1.29612,
        lng: 103.78133,
        name: "NUS",
        address: "1E Kent Ridge Rd, Level 11, Singapore 119228",
        type: "school",
    },
    {
        lat: 1.34494,
        lng: 103.68157,
        name: "NTU",
        address: "52 Nanyang Ave, Singapore 639798",
        type: "school",
    },
    {
        lat: 1.29098,
        lng: 103.85765,
        name: "PSB",
        address: "6 Raffles Blvd, #03 - 200, Singapore 039594",
        type: "school",
    },
    {
        lat: 1.29621,
        lng: 103.84359,
        name: "SHRM",
        address: "120 Oxley Rise, Singapore 238709",
        type: "school",
    },
    {
        lat: 1.32956,
        lng: 103.77623,
        name: "SIM",
        address: "461 Clementi Rd, Singapore 599491",
        type: "school",
    },
    {
        lat: 1.29658,
        lng: 103.85197,
        name: "SMU",
        address: "81 Victoria St, Singapore 188065",
        type: "school",
    },
    {
        lat: 1.29615,
        lng: 103.81557,
        name: "Stanfort Academy",
        address: "1 Kay Siang Rd, Singapore 248922",
        type: "school",
    },
    {
        lat: 1.29931,
        lng: 103.84816,
        name: "SOTA",
        address: "1 Zubir Said Dr, Singapore 227968",
        type: "school",
    },
    {
        lat: 1.31524,
        lng: 103.89202,
        name: "TMC",
        address: "805 Geylang Rd, Singapore 389683",
        type: "school",
    },
]


export function ListenWindowSize() {
    const is_mobile = ref(window.innerWidth < 768);


    const updateSize = () => {
        is_mobile.value = window.innerWidth < 768
    };

    onMounted(() => {
        window.addEventListener('resize', updateSize);
    });

    onUnmounted(() => {
        window.removeEventListener('resize', updateSize);
    });

    return is_mobile;
}


export const isBackstageRoute = computed(() => {
    // 判断路由元信息中是否包含 isBackstage 标识
    return router.currentRoute.value.meta.isBackstage;
});

var BedSizeSuggestions = [
    { value: "queensize" },
    { value: "single" },
    { value: "super single" },
    { value: "queen size" },
    { value: "king size" },
];


export const BedSizeQuerySearch = (queryString, cb) => {
    const results = queryString
        ? BedSizeSuggestions.filter((suggestion) =>
            suggestion.value.toLowerCase().includes(queryString.toLowerCase())
        )
        : BedSizeSuggestions;
    cb(results);
};


export async function FormCheck(fromRef) {
    if (!fromRef) return false
    return await fromRef.validate((valid, fields) => {
        return valid
    })
}

export function UploadRefFunction(upload_ref) {
    // 用于管理uploadRef
    const ExceedUploadImage = (files) => {
        upload_ref.value.clearFiles();
        const file = files[0];
        file.uid = genFileId();
        upload_ref.value.handleStart(file);
    };

    return {
        ExceedUploadImage,
    };
}


export function ChangeHash(hash){
    window.location.hash = '#' + hash
}

export function JumpTo(url){
    window.open(url, '_blank');
}