import { createRouter, createWebHistory } from "vue-router"
import Cookies from "js-cookie"
import store from "@/store";
import requests from "@/request/requests";
import { common_response_code } from "@/setting/settings";
import { PermissionCheck, USER_ROLE } from "@/assets/js/permission";
import api from "@/request/api";

const routes = [
    {
        path: "/homebase",
        name: "homebase",
        component: () => import("@/components/base/WebBase"),
        children: [

            {
                path: '/footer',
                name: 'footer',
                component: () => import('@/components/base/FooterBase'),
                children: [
                    {
                        path: "/frontend",
                        name: "frontend",
                        component: () => import("@/components/base/FrontendBase"),
                        children: [
                            {
                                path: "/",
                                name: "search",
                                component: () => import("@/views/SearchPage"),
                            },
                            {
                                path: "/guides/:index_name",
                                name: "guide",
                                component: () => import("@/views/article/ArticleDetail"),
                            },{
                                path: "/m/:cname/",
                                name: "collection",
                                component: () => import("@/views/SearchPage"),
                            },
                            {
                                path: "/d/:uuid/",
                                name: "house",
                                component: () => import("@/views/HousePage"),
                            },
                        ]
                    },
                    {
                        path: "/backstage",
                        name: "backstage",
                        component: () => import("@/components/base/BackstageBase"),
                        meta: { requiresAuth: 'all', isBackstage: true },
                        children: [

                            {
                                path: "/listing/upload/",
                                name: "listing_upload",
                                component: () => import("@/views/listing/UploadListing"),
                                meta: { requiresAuth: [USER_ROLE.LANDLORD, USER_ROLE.GLOBAL_CS, USER_ROLE.COMPANY, USER_ROLE.SUPERUSER], isBackstage: true },
                            }, {
                                path: "/unit/upload/",
                                name: "unit_upload",
                                component: () => import("@/views/unit/UploadUnit"),
                                meta: { requiresAuth: [USER_ROLE.LANDLORD, USER_ROLE.GLOBAL_CS, USER_ROLE.COMPANY, USER_ROLE.SUPERUSER], isBackstage: true },
                            }, {
                                path: "/room/upload/",
                                name: "room_upload",
                                component: () => import("@/views/room/UploadRoom"),
                                meta: { requiresAuth: [USER_ROLE.LANDLORD, USER_ROLE.GLOBAL_CS, USER_ROLE.COMPANY, USER_ROLE.SUPERUSER], isBackstage: true },
                            }, {
                                path: "/dashboard",
                                name: "dashboard",
                                component: () => import("@/views/DashboardPage"),
                                meta: { requiresAuth: "all", isBackstage: true },
                            }, {
                                path: "/profile",
                                name: "profile",
                                component: () => import("@/views/user/UserProfile"),
                                meta: { requiresAuth: 'all', isBackstage: true },
                            }, {
                                path: "/article/upload",
                                name: "article_upload",
                                component: () => import("@/views/article/ArticleUpload"),
                                meta: { requiresAuth: [USER_ROLE.SUPERUSER], isBackstage: true },
                            }, {
                                path: "/become",
                                name: "become",
                                component: () => import("@/views/BecomeLandlord"),
                                meta: { requiresAuth: [USER_ROLE.USER], isBackstage: true },
                            }, {
                                path: "/landlord/approval",
                                name: "landlord_approval",
                                component: () => import("@/views/approval/LandlordApproval"),
                                meta: { requiresAuth: [USER_ROLE.GLOBAL_CS, USER_ROLE.SUPERUSER], isBackstage: true },
                            }, {
                                path: "/company/approval",
                                name: "company_approval",
                                component: () => import("@/views/approval/CompanyApproval"),
                                meta: { requiresAuth: [USER_ROLE.GLOBAL_CS, USER_ROLE.SUPERUSER], isBackstage: true },
                            }, {
                                path: '/poi/detail/:key/',
                                name: 'poi_detail',
                                component: () => import("@/views/poi/PoiDetail"),
                                meta: { requiresAuth: [USER_ROLE.GLOBAL_CS, USER_ROLE.SUPERUSER], isBackstage: true },
                            }, {
                                path: '/poi/upload',
                                name: 'poi_upload',
                                component: () => import("@/views/poi/PoiUpload"),
                                meta: { requiresAuth: [USER_ROLE.GLOBAL_CS, USER_ROLE.SUPERUSER], isBackstage: true },
                            }, {
                                path: '/listing/detail/:key/',
                                name: 'listing_detail',
                                component: () => import("@/views/listing/ListingDetail"),
                                meta: { requiresAuth: [USER_ROLE.LANDLORD, USER_ROLE.GLOBAL_CS, USER_ROLE.COMPANY, USER_ROLE.SUPERUSER], isBackstage: true },
                            },{
                                path: '/listing/draft/',
                                name: 'listing_draft',
                                component: () => import("@/views/listing/ListingDraft"),
                                meta: { requiresAuth: [USER_ROLE.LANDLORD, USER_ROLE.GLOBAL_CS, USER_ROLE.COMPANY, USER_ROLE.SUPERUSER], isBackstage: true },
                            }, {
                                path: '/unit/detail/:key/',
                                name: 'unit_detail',
                                component: () => import("@/views/unit/UnitDetail"),
                                meta: { requiresAuth: [USER_ROLE.LANDLORD, USER_ROLE.GLOBAL_CS, USER_ROLE.COMPANY, USER_ROLE.SUPERUSER], isBackstage: true },
                            }, {
                                path: "/article",
                                name: "article",
                                component: () => import("@/views/article/ArticleManage"),
                                meta: { requiresAuth: [USER_ROLE.SUPERUSER], isBackstage: true },
                            }, 
                            {
                                path: "/my-company",
                                name: "my_company",
                                component: () => import("@/views/company/MyCompany"),
                                meta: { requiresAuth: [USER_ROLE.COMPANY], isBackstage: true },
                            },
                            {
                                path: '/company/create',
                                name: 'company_create',
                                component: () => import("@/views/company/CreateCompany"),
                                meta: { requiresAuth: [USER_ROLE.COMPANY], isBackstage: true },
                            },
                            {
                                path: '/poi',
                                name: 'poi',
                                component: () => import("@/views/poi/PoiManage"),
                                meta: { requiresAuth: [USER_ROLE.GLOBAL_CS, USER_ROLE.SUPERUSER], isBackstage: true },
                            }, {
                                path: 'company/management/',
                                name: 'company_management',
                                component: () => import("@/views/company/CompanyManage"),
                                meta: { requiresAuth: [USER_ROLE.SUPERUSER], isBackstage: true },
                            },{
                                path: "/unit",
                                name: "unit",
                                component: () => import("@/views/unit/UnitManage"),
                                meta: { requiresAuth: [USER_ROLE.LANDLORD, USER_ROLE.GLOBAL_CS, USER_ROLE.COMPANY, USER_ROLE.SUPERUSER], isBackstage: true },
                            }, {
                                path: "/unit/draft",
                                name: "unit_draft",
                                component: () => import("@/views/unit/UnitDraft"),
                                meta: { requiresAuth: [USER_ROLE.LANDLORD, USER_ROLE.GLOBAL_CS, USER_ROLE.COMPANY, USER_ROLE.SUPERUSER], isBackstage: true },
                            },{
                                path: "/listing",
                                name: "listing",
                                component: () => import("@/views/listing/ListingManage"),
                                meta: { requiresAuth: [USER_ROLE.LANDLORD, USER_ROLE.GLOBAL_CS, USER_ROLE.COMPANY, USER_ROLE.SUPERUSER], isBackstage: true },
                            },
                            {
                                path: "/users",
                                name: "users",
                                component: () => import("@/views/user/UserManage"),
                                meta: { requiresAuth: [USER_ROLE.SUPERUSER], isBackstage: true },
                            }, {
                                path: "/permission",
                                name: "permission",
                                component: () => import("@/views/user/PermissionManage"),
                                meta: { requiresAuth: [USER_ROLE.SUPERUSER], isBackstage: true },
                            },{
                                path: "/tenant/manage/",
                                name: "tenant_management",
                                component: () => import("@/views/tenant/TenantManage"),
                                meta: { requiresAuth: [USER_ROLE.LANDLORD], isBackstage: true },
                            }, {
                                path: "/tenant/add/",
                                name: "tenant_add",
                                component: () => import("@/views/tenant/TenantAdd"),
                                meta: { requiresAuth: [USER_ROLE.LANDLORD], isBackstage: true },
                            }, {
                                path: "/tenancy/add/",
                                name: "tenancy_add",
                                component: () => import("@/views/tenant/TenancyAdd"),
                                meta: { requiresAuth: [USER_ROLE.COMPANY, USER_ROLE.LANDLORD], isBackstage: true },
                            }
                        ]
                    }
                ]

            },
        ]
    }
];

const router = createRouter({
    history: createWebHistory(),  // 路由不带#
    routes,
    scrollBehavior(to, from, savedPosition) {
        return { left: 0, top: 0 }
    }
});

router.beforeEach(async (to, from, next) => {
    let token = Cookies.get("token");
    let role = localStorage.getItem("roles")
    if (!store.state.user.isLogin && token && role) {
        try {
            const res = await api.async_request("/user/info/", "GET", {params: {role: role}});
            if (res.code === common_response_code) {
                store.commit("setUserInfo", res.data);
                store.commit("setUserState", true);
                if(!to.meta.requiresAuth || to.meta.requiresAuth=='all' || PermissionCheck(to.meta.requiresAuth)){
                    next()
                }else{
                    next(to.fullPath)
                }
            } else {
                next('/');
            }

        } catch (error) {
            console.error("Failed to fetch user info:", error);
            next(from.fullPath);
        }
    }

    if (!to.meta.requiresAuth) {
        next();
    } else {
        if (store.state.user.isLogin) {    
            if(to.meta.requiresAuth=='all' || PermissionCheck(to.meta.requiresAuth)){
                next()
            }else{
                next(from.fullPath)
            }
        } else {
            next("/")
        }

    }
});


export default router