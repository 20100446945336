<template>
  <a-config-provider :theme="themeConfig">
  <router-view></router-view>
  </a-config-provider>
</template>
<script setup>
import { inject, watch, reactive } from "vue";
import { useRoute } from 'vue-router';
const themeConfig = reactive({
  token: {
    colorPrimary: '#00a76f', // 设置全局背景颜色
    Button: {
      colorPrimary: '#00a76f',
    },
    // 更多的主题变量
  },
});

</script>
<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  height: auto;
  flex: 1;
}
/* 
@media (max-width: 990px) {
  #app {
    height: auto;
  }
} */

body {
  height: 100% !important;
  line-height: 2;
  display: flex;
  flex-direction: column;
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
}

nav a.router-link-exact-active {
  color: #42b983;
}

html,
body {
  width: 100%;
  height: 100%;
  scroll-behavior: smooth;
  margin: 0px;
  padding: 0px;
}
</style>
