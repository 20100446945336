import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
import requests from "@/request/requests"
import Cookies from "js-cookie"
import i18n from './language'
import Antd from 'ant-design-vue';
import 'intl-tel-input/build/css/intlTelInput.css';
import "@/assets/css/global.css";
import Vue3CountryIntl from 'vue3-country-intl';
import 'vue3-country-intl/lib/vue3-country-intl.css'
import BaiduMap from 'vue-baidu-map-3x';
// import TawkMessengerVue from '@tawk.to/tawk-messenger-vue-3';
import { map_key } from "@/setting/settings";
const app = createApp(App)
app.component(Vue3CountryIntl.name, Vue3CountryIntl);
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
    app.component(key, component)
}
app.use(BaiduMap, {
    ak: map_key,
});
app.use(router)
app.use(Antd)
app.use(ElementPlus)
app.use(i18n)
// app.use(TawkMessengerVue, {
//     propertyId : process.env.VUE_APP_PROPERTY_ID,
//     widgetId : process.env.VUE_APP_WIDGET_ID
// });
app.config.globalProperties.$cookies = Cookies
app.config.globalProperties.$axios = requests
app.config.globalProperties.$i18n.onLanguageSwitched = (oldLocale, newLocale) => {
    document.documentElement.lang = newLocale;
};
router.isReady().then(() => app.mount('#app'))
