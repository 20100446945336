import requests from "@/request/requests";
import { common_response_code } from "@/setting/settings";
const user = {
    // 全局状态
    state: {
        // 用户信息
        userInfo: {
            roles: []
        },
        isLogin: false,
    },
    // getters是对数据的包装，例如对数据进行拼接，或者过滤
    getters: {
        //类似于计算属性

    },
    // 如果我们需要更改store中的状态，一定要通过mutations来进行操作
    mutations: {

        // 传入自定义参数
        setUserInfo: function (state, userInfo) {
            state.userInfo = userInfo
        },
        setUserState: function (state, isLogin) {
            state.isLogin = isLogin
        }
    },

    // actions是我们定义的一些操作，正常情况下，我们很少会直接调用mutation方法来改变state
    actions: {
        async UpdateUserInfo({ commit }, state) {
            const res = await requests.get("/user/info/", "GET", {});
            if (res.code == common_response_code) {
                commit('setUserInfo', res.data)

            }
        },
    }
}
export default user
