import axios from "axios"
import router from '../router/index'
import Cookies from 'js-cookie'
import { ElMessage } from 'element-plus';
import { clean_account_info, clean_token_info } from "@/assets/js/common"
import { server_host, server_protocol, server_port } from "@/setting/settings";
import i18n from "@/language";
const { locale, t } = i18n.global

const refresh_token = async () => {
    try {
        var refresh_token = Cookies.get('refresh')
        var response = await axios.post(server_protocol + server_host + ':' + server_port + '/api/token/refresh/', { refresh: refresh_token })
        if (response.data.access) {
            return response.data.access
        } else {
            return null
        }
    } catch {
        return null
    }
}

const requests = axios.create({
    baseURL: server_protocol + server_host + ':' + server_port + '/api',
    timeout: 60000
})

requests.interceptors.request.use(config => {
    config.headers['Accept-Language'] = locale.value
    var token = Cookies.get("token")
    if (token != undefined) {
        config.headers.Authorization = "Bearer " + token;
        

    }
    config.headers['Access-Control-Allow-Origin'] = 'Authorization'
    config.headers['role'] = localStorage.getItem('roles')
    return config
}, err => {
    return Promise.reject(err);
})

requests.interceptors.response.use((success) => {
    if (success.data.msg) {
        ElMessage.success(success.data.msg)
    }
    return success.data;
}, async (error) => {
    const originalRequest = error.config;
    if (error.response?.status == 504 || error.response?.status == 404) {
        if (!error.response.data.data.err) {
            ElMessage.error({ message: 'Server Error' });
        }
    } else if (error.response?.status == 403) {
        if (!error.response.data.data.err) {
            ElMessage.error({ message: 'Request Refused' });
        }
    } else if (error.response?.status == 401) {
        if (originalRequest._retry) {
            if(error.response?.data?.data?.err){
                ElMessage.error({ message: error.response.data.data.err });
            }
            router.push("/")
            return error.response?.data
        }
        originalRequest._retry = true;
        const token = await refresh_token();
        Cookies.set('token', token)
        if (token) {
            return requests(originalRequest);
        } else {
            clean_token_info()
            clean_account_info()
            const requiresAuth = router.currentRoute.value.meta.requiresAuth;
            if (requiresAuth) {
                router.push('/');
            }
        }
        return error.response?.data
    } else {

        if (error.response?.data?.data?.err) {
            ElMessage.error({ message: error.response.data.data.err });
        } else {
            console.log('error:', error)
            // ElMessage.warning({ message: 'Unkown Error' });
        }
    }
    return error.response?.data
});

export default requests;
