export default {
    CANCEL: "Cancel",
    CONFIRM: "Comfirm",
    LOGIN: "Login",
    NAME: "Name",
    AVATAR: "Avatar",
    EMAIL: "Email",
    'PLEASE ENSURE THAT THE FOLLOWING INFORMATION HAS BEEN FILLED IN: FULL NAME, DATE OF BIRTH, PASS EXPIRY DATE, PASS TYPE, FIN': 'Please ensure that the following information has been filled in: Full Name, Date of Birth, Pass Expiry Date, Pass Type, FIN',
    'HELLO ': 'Hello ',
    'GENERATING':'Generating...',
    'PASS EXPIRY DATE': 'Pass Expiry Date',
    'DRAFT': 'Draft',
    'MY RENTAL': 'My Business',
    "VIDEOS": 'Videos',
    'ALL UPLOADED DATA REMAINS THE PROPERTY OF YOUR COMPANY. BY UPLOADING DATA, YOUR COMPANY GRANTS THIS PLATFORM THE RIGHT TO USE IT AS NEEDED. YOUR COMPANY IS SOLELY RESPONSIBLE FOR ENSURING THE LEGAL COMPLIANCE OF THE DATA IT UPLOADS.': 'All uploaded data remains the property of your company. By uploading data, your company grants this platform the right to use it as needed. Your company is solely responsible for ensuring the legal compliance of the data it uploads.',
    "OUR PLATFORM IS RESPONSIBLE FOR PROVIDING YOUR COMPANY'S ROOM INFORMATION TO POTENTIAL TENANTS, WHILE THE LEGAL DETAILS OF THE CONTRACT ARE THE RESPONSIBILITY OF THE CO-LIVING COMPANY, WHICH MAY CHOOSE TO DELEGATE THIS TO A CEA LICENCED REAL ESTATE AGENT.": "Our platform is responsible for providing your company's room information to potential tenants, while the legal details of the contract are the responsibility of the co-living company, which may choose to delegate this to a CEA licenced real estate agent.",
    'MOST BASIC SERVICES ON THIS PLATFORM ARE FREE OF CHARGE. FOR ANY SERVICES THAT INCUR A FEE, WE WILL COMMUNICATE WITH YOU IN ADVANCE BEFORE ANY CHARGES ARE APPLIED.': 'Most basic services on this platform are free of charge. For any services that incur a fee, we will communicate with you in advance before any charges are applied.',
    'MY BUSINESS': 'My Business',
    'COMPANY PROFILE': 'Company Profile',
    'ACCESS CONTROL': 'Access Control',
    'PROPERTY INFORMATION': 'Property Information',
    'AC MAINTANENCE': 'AC Maintanence',
    'CLEANING': 'Cleaning',
    'RENOVATION' : 'Renovation',
    'MY LEASES': 'My Leases',
    'CREATE TA TEMPLATE': 'Create TA Template',
    'CREATE TA': 'Create TA',
    'TA MANAGEMENT': 'TA Management',
    "PLEASE SELECT {NAME}": "Please Select {NAME}",
    'TENANT PROFILE': 'Tenant Profile',
    'UPLOAD DOCUMENT': 'Upload Document',
    'MY FINANCE': 'My Finance',
    'ADD TENANT': 'Add Tenant',
    'DOCUMENT IMAGE(BACK)': 'Document Image(Back)',
    'DOCUMENT IMAGE(FRONT)': 'Document Image(Front)',
    'BIRTH DATE': 'Date of Birth',
    'TENANT ADDED SUCCESSFULLY': 'Tenant Added Successfully',
    'TENANT ADDED FAILED': 'Tenant Added Failed',
    '{NAME} REQUIRED': '{NAME} is required',
    'RENTAL REMINDER': 'Rental Reminder',
    'RENT REMINDER DATE': 'Rent Reminder Date',
    'PAYMENT LINK': 'Payment Link',
    'GENERATE REMINDER TEMPLATE': 'Generate Reminder Template',
    'RENT REMINDER INFO TEMPLATE': 'Rent Reminder Info Template',
    'RENT REMINDER INFO' : 'Rent Reminder Info',
    'GENERATED RENT REMINDER INFO': 'Generated Rent Reminder Info',
    'BASIC TEMPLATE': 'Basic Template',
    'TEMPLATE 1': 'Template 1',
    'TEMPLATE 2': 'Template 2',
    'CUSTOM TEMPLATE': 'Custom Template',
    'IDENTITY TYPE': 'Identity Type',
    '_NAME':'Name',
    'FIN TYPE': 'Pass Type',
    'WEEKLY PASS CHECK': 'Weekly Pass Check',
    'IDENTITY NUMBER': 'Identification Number',
    'DETECTION IDENTITY NUMBER': 'Detection Identity Number',
    PASSPORT: "Passport",
    'COUNTRY CODE': 'Country Code',
    'CREATE TENANCY': 'Create Tenancy',
    'MASTER LEASE MANAGEMENT': 'Master Lease',
    "DO YOU WANT TO USE THE LAST LOGIN EMAIL?": "Do you want to use the last login email?",
    'MONTHLY PAYMENT MANAGEMENT': 'Monthly Mortgage',
    'UTILITIES MANAGEMENT': 'Utilities',
    'HISTORICAL RENT RECORDS': 'Rent Records',
    'RENT ARREARS NOTIFICATION': 'Rent Alerts',
    'RENT INCOME CASH FLOW': 'Cash Flow',
    'SAVE AS DRAFT':'Save as Draft',
    'CURRENT RENTAL': 'My Rental',
    'ROOM PASSWORD': 'Room Password',
    'CREATE PASSWORD': 'Create Password',
    'DELETE PASSWORD': 'Delete Password',
    'PAY RENT': 'Pay Rent',
    'CONTRACT': 'Contract',
    'TYPE IN YOUR EMAIL TO RECEIVE A PASSCODE': 'Type in your email to receive a passcode',
    'OFFICIAL IMAGES': 'Official Images',
    'OFFICIAL VIDEOS': 'Official Videos',
    'SINGAPORE COMPANY': '© Singapore SingHome Joy Pte. Ltd.',
    'CHINESE COMPANY': 'Strategic partner of Shenzhen SingHome Joy Business Co., Ltd.',
    'COMPANY ADDRESS':'14 Robinson Road, #08-01A, Far East Finance Building, Singapore 048545',
    'LOG IN OR SIGN UP': 'Log In or Sign Up',
    'LONGITUDE': 'longitude',
    'POSTCODE LENGTH CANNNOT BE LESS THAN 6': 'Postcode length cannot be less than 6',
    "LATITUDE": "latitude",
    'UPLOADING HEIC FORMAT FILES WILL AUTOMATICALLY CONVERT THEM TO JPG FORMAT IMAGES. EACH IMAGE TAKES ABOUT 2 SECONDS, SO PLEASE BE PATIENT.':'Uploading HEIC format files will automatically convert them to JPG format images. Each image takes about 2 seconds, so please be patient.',
    'COOKING ALLOWED': 'Cooking Allowed',
    'PET ALLOWED': 'Pet Allowed',
    '. PLEASE CHECK AND ENTER THE CODE HERE TO CONTINUE SIGNING UP.': '. Please check and enter the code here to continue signing up.',
    'WE SENT YOU A CODE AT ': 'We sent you a code at ',
    "WELCOME TO SINGHOMEJOY": "welcome to SingHomeJoy",
    VERIFY: "Confirm Email",
    'HELLO {EMAIL}, THE VERIFICATION CODE HAS BEEN SENT TO YOUR EMAIL, PLEASE FILL IN THE VERIFICATION CODE': 'Hello {EMAIL}, The verification code has been sent to your email, please fill in the verification code',
    "PLEASE INPUT EMAIL": "please input email",
    'ACCOUNT CREATED': 'Account Created',
    'FILL IN THE CHINESE TITLE OF THE ARTICLE. IF NOT FILLED IN, IT WILL BE AUTOMATICALLY TRANSLATED.': 'Fill in the Chinese title of the article. If not filled in, it will be automatically translated.',
    "PLEASE ENTER YOUR EMAIL": "please enter your email",
    "PLEASE INPUT PASSWORD": "please input password",
    "LOOKS LIKE YOU DON'T HAVE AN ACCOUNT WITH THAT EMAIL ADDRESS, SIGN UP TO GET ALL OF THESE BENEFITS:": "looks like you don’t have an account with that email address, sign up to get all of these benefits:",
    "SIGN UP FOR A NEW ACCOUNT": "sign up for a new account",
    "MY ACCOUNT": "My Account",
    'CONTINUE': 'Continue',
    'VIEW': 'View',
    'VIDEO URLS': 'Video URLs',
    'VIDEO FILES': 'Video Files',
    'ARE YOU SURE TO DELETE THIS USER?': 'Are you sure to delete this user?',
    'UPLOAD': 'Upload',
    'VIDEO UPLOAD': 'Video Upload',
    '{NUM} FILES SELECT': '{NUM} Files Selected',
    'BY UPLOAD': 'By Upload',
    'BY URL': 'By URL',
    LOGOUT: "Log Out",
    'ARTICLE TITLE(ZH)': 'Article Chinese title',
    'ARTICLE CONTENT(ZH)': 'Article Chinese content',
    REGISTER: "register",
    "PLEASE INPUT CODE": "please input code",
    "WRONG EMAIL FORMAT": "wrong email format",
    RENT: "Rent",
    "SECURITY SETTINGS": "Security Settings",
    EDIT: "Edit",
    "CHANGE AVATAR": "Change Avatar",
    FILTER: "Filter",
    "SHOW LESS": "show less",
    "SHOW MORE": "show more",
    'PREVIEW': 'Preview',
    "LOCATION": 'Location',
    PROPERTY: "property",
    "CUSTOMER SERVICE": "customer service",
    OVERVIEW: "overview",
    'LAST UPDATED': 'Last Updated',
    BEDROOM: "bedroom",
    PRICE: "price",
    'ROOM LABEL NOTIFICATION': "The room label is crucial as it identifies the specific room in the tenancy agreement (TA). Examples include 'Master Bedroom,' 'Common Room 1'/'CR1', 'Balcony Room,' or unique names such as 'Solar.'",
    'ARTICLES': 'Articles',
    'UPLOAD ARTICLE': 'Upload Article',
    SORT: "Sort",
    "SELECT THE LANGUAGE TO DESCRIBE THE INFORMATION AND CLICK 'TRANSLATION' TO AUTOMATICALLY TRANSLATE IT INTO ANOTHER LANGUAGE.": "Select the language to describe the information and click 'Translation' to automatically translate it into another language.",
    AREA: "area",
    "HIGH TO LOW": "high to low",
    'TRANSLATE': 'Translate',
    "LOW TO HIGH": "low to high",
    LINK: "link",
    "LINK WECHAT": "link WeChat",
    "LINK WHATSAPP": "link WhatsApp",
    LATEST: "Latest",
    "MOST ECONOMICAL": "Value for money",
    "LESS THAN": "less than",
    MIN: "min",
    'AREA - HIGH TO LOW': 'Area - high to low',
    'AREA - LOW TO HIGH': 'Area - low to high',
    'PRICE - HIGH TO LOW': 'Price - high to low',
    'PRICE - LOW TO HIGH': 'Price - low to high',
    "MONTH": 'Month',
    'CHECKING DOCUMENT VALIDITY...': 'Checking document validity...',
    'CREATING DOCUMENT...': 'Creating document...',
    'BELOW': 'Below S$ {price}',
    'CREATOR': 'Creator',
    "ABOVE": 'Above S$ {price}',
    'DETAILS': 'Details',
    'CONTACT': 'Contact',
    COMMUTE: "Commute",
    "SEE DETAIL": "See Detail",
    SHARE: "Share",
    'HOMEPAGE PREVIEW': 'Homepage Preview',
    'COMPANY MEMBERS':'Members',
    '_RENT': 'Rent',
    "PLANNING AREA": 'Planning Area',
    "ALL FEMALE": "All Female Env",
    "ALL MALE": "all male",
    MIX: "mix",
    'PROPERTY TYPE': 'Property Type',
    UNSET: "unset",
    'ROOMS': 'Rooms',
    GENDER: "gender",
    "MOVE IN DATE": "Move-In Date",
    'ROOMS LIST': 'Rooms List',
    'ACTION': 'Action',
    'COMPANY DETAILS': 'Company',
    'WEBSITE': 'Website',
    'MY COMPANY': 'Profile',
    'POSITION': 'Position',
    "MORE DETAILS": "More Details",
    "ABOUT THIS PROPERTY": "About This Property",
    "UNIT SIZE": "unit size",
    "MOVE IN": "move in",
    TENURE: "tenure",
    'CREATOR INFORMATION': 'Creator Information',
    'MINI GOLF RANGE': 'Mini Golf Range',
    "TOP YEAR": "top year",
    DEVELOPER: "Dveloper",
    FURNISHING: "furnishing",
    "FLOOR LEVEL": "floor level",
    "TOTAL UNITS": "total units",
    'LIFT LOBBY': 'Lift Lobby',
    'LEISURE POOL': 'Leisure Pool',
    'WECHAT QRCODE URL': 'WeChat QRCode URL',
    'BBQ PAVILLION': 'Bbq Pavillion',
    'COMMUNITY PLAZA': 'Community Plaza',
    'CHANGING ROOM': 'Changing Room',
    'BASKETBALL COURT': 'Basketball Court',
    'KARAOKE': 'Karaoke',
    'LOUNGE': 'Lounge',
    'JOGGING TRACK': 'Jogging Track',
    'CHILDRENS PLAYGROUND': 'Childrens Playground',
    'PANTRY': 'Pantry',
    'RECREATION ROOM': 'Recreation Room',
    'LAUNDERETTE': 'Launderette',
    'MULTI-STOREY CAR PARK': 'Multi-storey Car Park',
    'ADVENTURE PARK': 'Adventure Park',
    "CHILDREN'S POOL": "Children's Pool",
    'BILLIARDS ROOM': 'Billiards Room',
    'COASTAL COURTYARD': 'Coastal Courtyard',
    'ROOF TERRACE': 'Roof Terrace',
    'DROP OFF POINT': 'Drop Off Point',
    'AEROBIC POOL': 'Aerobic Pool',
    'DANCE STUDIO': 'Dance Studio',
    'ELECTRICAL SUB-STATION': 'Electrical Sub-station',
    'ELDERLY WELLNESS STATIONS': 'Elderly Wellness Stations',
    'STEAM BATH': 'Steam Bath',
    'GAME ROOM': 'Game Room',
    'UTILITIES CENTER': 'Utilities Center',
    "UPDATE POI FAILED": 'Update Poi Failed',
    'EXISTING BUS STOP': 'Existing Bus Stop',
    'COMPLETE': 'Complete',
    'SKY GARDEN': 'Sky Garden',
    'BADMINTON HALL': 'Badminton Hall',
    'PRECINCT PAVILION': 'Precinct Pavilion',
    "RELATED PROJECT": 'Related Project',
    'POOL DECK': 'Pool Deck',
    'LAP POOL': 'Lap Pool',
    "CHANGE": 'Change',
    'AFFILIATED COMMUNITY': 'Project',
    "CUSTOMER SERVICE NAME CANNOT BE EMPTY": 'Customer service name cannot be empty',
    'READY TO MOVE-IN': 'Ready to move-in',
    "WITHIN 2 MONTHS": 'Within 2 months',
    "WITHIN 1 MONTH": 'Within 1 month',
    "WITHIN 1 WEEK": 'Within 1 week',
    'ANY DATE': 'any date',
    "PICK MOVE IN DATE": 'pick move in date',
    'BOWLING ALLEY': 'Bowling Alley',
    "CURRENT TENANTS": "current tenants",
    'CAFES': 'Cafes',
    'CO-WORKING SPACE': 'Co-Working Space',
    'PET CORNER': 'Pet Corner',
    'GARDEN TERRACE': 'Garden Terrace',
    'PARTY LAWN': 'Party Lawn',
    'MINI-MART': 'Mini-Mart',
    'DRIVING RANGE': 'Driving Range',
    SHOPPING: "Shopping",
    'POOLSIDE LOUNGE': 'Poolside Lounge',
    'SHELTERS': 'Shelters',
    'SKYLINE TERRACE': 'Skyline Terrace',
    'AMPHITHEATRE': 'Amphitheatre',
    'MAIN ENTRANCE': 'Main Entrance',
    "CHILDREN'S PLAYGROUND": 'Children`s Playground',
    SCHOOLS: "Schools",
    'AIRCON FACILITIES': 'Aircon Facilities',
    'MEETING ROOM': 'Meeting Room',
    'REFLEXOLOGY PATH': 'Reflexology Path',
    'ADULT FITNESS STATIONS': 'Adult fitness stations',
    'COVERED LINKWAYS': 'Covered linkways',
    'CHILDRENS POOL': 'Childrens pool',
    "CONDO FACILITIES": "Condo Facilities",
    'COVER IMAGE': 'Cover Image',
    "SWIMMING POOL": "swimming pool",
    'SQUASH COURT': "Squash court",
    "BARBEQUE AREA": "barbeque area",
    "COVERED CAR PARK": "covered car park",
    "CAR PARK": "car park",
    "FORCE UPLOAD": "Force Upload",
    'PHOTO UPLOAD SUCCESS': 'Photo Upload Success',
    'PHOTO UPLOAD FAIL': 'Photo Upload Fail',
    'PHOTO UPLOAD': 'Photo Upload',
    'PHOTO URL UPLOAD SUCCESS': 'Photo URL Upload Success',
    'IMAGE VERIFICATION FAILED, DO YOU WANT TO FORCE UPLOAD?': 'Image verification failed, do you want to force upload?',
    'VIDEO VERIFICATION FAILED, DO YOU WANT TO FORCE UPLOAD?': 'Video verification failed, do you want to force upload?',
    'PHOTO URL UPLOAD FAIL': 'Photo URL Upload Fail',
    'PHOTOS URL UPLOAD': 'Photos URL Upload',
    "LIVING ROOM FURNITURE": "living room furniture",
    'PHOTO URL': 'Photo URL',
    'VIDEO URL': 'Video URL',
    SAUNA: "sauna",
    "GYMNASIUM ROOM": "gymnasium room",
    'URL UPLOAD': 'URL Upload',
    'NO INTERFACE ADDRESS CONFIGURED': "No interface address configured",
    "24 HOURS SECURITY": "24 hours security",
    "FITNESS CORNER": "fitness corner",
    CLUBHOUSE: "clubhouse",
    "FUNCTION ROOM": "function room",
    JACUZZI: "jacuzzi",
    PLAYGROUND: "playground",
    "SPA POOL": "spa pool",
    "WADING POOL": "wading pool",
    "TENNIS COURTS": "tennis courts",
    "OPEN CAR PARK": "open car park",
    "BASEMENT CAR PARK": "basement car park",
    LIBRARY: "library",
    "PUTTING GREEN": "putting green",
    PAVILLION: "pavillion",
    WIFI: "WiFi",
    'UNIT DETAIL': 'Unit Detail',
    'ARE YOU SURE TO DELETE THIS COMPANY': 'Are you sure to delete this company',
    "HYDROTHERAPY POOL": "hydrotherapy pool",
    "STUDY ROOM": "Study Room",
    "MULTI-PURPOSE HALL": "multi-purpose hall",
    "SETTINGS": "Settings",
    'DELETE COMPANY SUCCESS': 'Delete Company Success',
    'UPLOAD COMPANY LOGO': 'Upload Company Logo',
    "STEAM ROOM": "steam room",
    "FUN POOL": "fun pool",
    'EXCEED FILE LIMIT': 'Exceed file limit, The maximum number of files is {NUM}',
    'SEARCH COMPANY NAME': 'Search Company Name',
    'BACK': "Back",
    'VISIT WEBSITE': 'Visit Website',
    'ARE YOU SURE TO DELETE THIS COMPANY?': 'Are you sure to delete this company?',
    '_OPERATION': 'Data',
    "USERS": "Users",
    'CONTRACTS': 'Contracts',
    'COMPANIES': 'Companies',
    MORE: "more",
    'AGENCY LICENSE': 'Agency License',
    'REAL NAME': 'Real Name',
    'NRIC': 'National Registration Identity Card(NRIC)',
    'CEA NUMBER': 'Council for Estate Agencies(CEA) Number',
    'FIN': 'Foreign Identification Number(FIN)',
    'CEA NO': 'Council for Estate Agencies(CEA) Number',
    'CS DISPLAY NAME': 'Display Name',
    'LANDLORD APPROVAL': 'Landlord Approval',
    'BECOME LANDLORD': 'Become Landlord',
    'APPROVALS': 'Approvals',
    'APPROVAL SUCCESS': 'Approval Success',
    'APPROVAL FAILED': 'Approval Failed',
    'THANK YOU!': 'Thank You!',
    'REFUTE': 'Reject',
    'YOUR SUBMISSION WAS SUCCESSFUL. OUR CUSTOMER SERVICE TEAM WILL COMPLETE THE APPROVAL PROCESS AS SOON AS POSSIBLE. THIS MAY TAKE 1-2 WORKING DAYS, PLEASE BE PATIENT.': 'Your submission was successful. Our customer service team will complete the approval process as soon as possible. This may take 1-2 working days, please be patient.',
    DELETE: "Delete",
    'NO FILE UPLOAD': 'No File Upload',
    'DOWNLOAD': 'Download',
    SEARCH: "Search",
    "PERMISSION MANAGE": "Permission Manage",
    "BATCH DELETION": "Batch Deletion",
    "ADD USER": "Add New User",
    "MODIFY PERMISSIONS": "Modify Permissions",
    MODIFY: "modify",
    'REGISTER AND LOGIN': 'Register and Login',
    "USER SELECTION": "User Selection",
    "ROLES SELECTION": "Roles Selection",
    "MY LISTINGS": "My Listings",
    "ADD NEW": "add new",
    "EXT NAME": "ext name",
    "EXT VALUE": "ext value",
    Y: "Yes",
    N: "No",
    ADVERTISE: "Advertise",
    "CREATE ROOM": "Create Room",
    "OFF SHELVES": "Unpublish",
    "ADD LISTING": "Add Listing",
    BACKSTAGE: "Admin Panel",
    'MEMBER LIST': 'Member List',
    '_UNSET': 'Unset',
    DASHBOARD: "dashboard",
    "CREATE SUCCESS": "Create Success!",
    "EDIT LISTING": "Edit Listing",
    "NO SET": "no set",
    CURRENCY: "Currency",
    "PRICE MIN": "Minimum rental price",
    "PRICE NORMAL": "Monthly Rent",
    "PRICE DESCRIPTION": "Price Description",
    "LISTING TYPE": "Listing Type",
    POSTCODE: "Postcode",
    DEPOSIT: "Deposit",
    "UNIT NO": "Unit NO",
    'TENANT': 'Tenant',
    'AGENT': 'Agent',
    'LANDLORD': 'Landlord',
    'COMPANY': 'Company',
    'ADMIN': 'Admin',
    'ADMIN PORTAL': 'Admin Portal',
    'SKY LOUNGE': 'Sky Lounge',
    "COMMUNITY GARDEN": "Community Garden",
    'ROOM': 'Room',
    "WIFI CHARGE": "WiFi Charge",
    "POSTCODE IS REQUIRED": "Postcode is required",
    "POSTCODE MUST BE EXACTLY 6 DIGITS": "Postcode must be exactly 6 digits",
    "PLEASE INPUT LISTING TYPE": "Please input listing type",
    "MAXIMUM CHARACTER LENGTH IS": "Maximum character length is",
    "INCORRECT POSTCODE": "Incorrect postcode",
    "PROJECT NAME": "Project Name",
    'WATING LOADING': 'Creating {PROPERTY_TYPE}...Please do not refresh the page',
    'PLEASE INPUT UNIT AREA': 'Please input unit area',
    "ROOM PHOTOS/VIDEO": "Room photos/video",
    'UPLOAD COVER': 'Upload Cover',
    "UNIT PHOTOS/VIDEO": "Unit photos/video",
    "CONDO PHOTOS/VIDEO": "Condo photos/video",
    "SURROUNDING PHOTOS/VIDEO": "Surrounding photos/video",
    "LAYOUT PHOTOS": "Layout photos",
    "DELETE SUCCESS": "Delete Success",
    'BECOME HOST': "Become Host",
    "DELETE FAIL": "Delete Fail",
    'COMPLETED': 'Completed',
    'GO TO SETTING': 'Go to setting',
    'PREVIOUS STEP': 'Previous Step',
    'COMPANY APPROVAL': 'Company Approval',
    'UEN': 'Unique Entity Number',
    'COMPANY EMAIL': 'Company Email',
    'COMPANY PHONE NUMBER': 'Company Phone Number',
    'NEXT STEP': "Next Step",
    'I AGREE TO THE ABOVE TERMS': 'I agree to the above terms',
    'CONGRATULATIONS, YOU HAVE BECOME A LANDLORD!': 'Congratulations, you have become a landlord!',
    OPTIONAL: "optional",
    'GUIDELINES FOR BECOMING A LANDLORD': 'Guidelines for Becoming a Landlord',
    'YOU ARE NOT CURRENTLY A LANDLORD, BUT BECOMING ONE ONLY TAKES A FEW STEPS.': "You are not currently a landlord, but becoming one only takes a few steps.",
    "UNIT RENTAL": "Unit Rental",
    'SORRY, THE APPLICATION PROCESS IS CURRENTLY NOT OPEN.': "Sorry, The application process is currently not open.",
    'YOUR REQUEST HAS BEEN SUBMITTED TO CUSTOMER SERVICE, AND THEY WILL RESPOND WITHIN 1-2 BUSINESS DAYS.': "Your request has been submitted to customer service, and they will respond within 1-2 business days.",
    "ROOM RENTAL": "Room Rental",
    "DEPOSIT DESCRIPTION": "Deposit Description",
    'UNIT DRAFT': 'Unit Draft',
    "PERSONAL CENTER": "Profile",
    'CONTACT INFORMATION': 'Contact Information',
    "PERSON PROFILE": "Profile",
    "TERMS TEXT": "I agree to HomeJoy's {TERMS}, {ACCEPTABLEUSE}, and {PRIVACYPOLICY}, including the collection, use, processing, storage, and disclosure of my personal information, as well as the regulation of my use of the website.{ACCOUNT}",
    "TERMS OF SERVICE": "Terms of Service",
    "ACCEPTABLE USE POLICY": "Acceptable Use Policy",
    "PRIVACY POLICY": "Privacy Policy",
    "BASIC INFORMATION": "Basic Information",
    "ACCOUNT APPEAL": "Account Appeal",
    'COMPANY HOMEPAGE BROWSING': 'Your Exclusive Homepage',
    "ACCOUNT BINDING": "Account Binding",
    EATERIES: "Eateries",
    "HIGHER EDUCTATION": "Higher Education",
    APARTMENT: "Apartment",
    HDB: "HDB",
    LANDED: "Landed",
    DESCRIPTION: "Description",
    'DESCRIPTION ZH': 'Description(zh)',
    "PLEASE INPUT UNIT LEVEL": "please input unit level",
    'CHANGE COVER': 'Change Cover',
    "PLEASE INPUT UNIT STACK": "please input unit stack",
    LEVEL: "Level",
    "ROOM TYPE": "Room Type",
    STACK: "Stack",
    'LANGUAGE': '语言',
    "MASTER ROOM": "Master Bedroom",
    "NOISE LEVEL": "Noise Level",
    "VERY QUIET": "very quiet",
    "QUIET": "quiet",
    "FACING POOL": "facing pool",
    "FACING SMALL ROAD": "facing small road",
    "FACING EXPRESSWAY": "facing expressway",
    "COMMON ROOM": "Common Room",
    "PARTITION ROOM": "Partition Room",
    "RENTAL TYPE": "Rental Type",
    "REJECTED": 'Rejected',
    "APPROVED": 'Approved',
    "APPLICANT NAME": 'Applicant Name',
    "APPLICANT EMAIL": 'Applicant Email',
    "APPLICANT WECHAT ID": 'Applicant WeChat ID',
    "APPLICANT WECHAT QR CODE": 'Applicant WeChat QR code',
    'APPROVAL': 'Approve',
    "FILE SIZE EXCEEDS THE LIMIT": "File size exceeds the limit",
    'REJECT': 'Reject',
    "MRT STATIONS": "mrt stations",
    'PLEASE FILL IN THE REQUIRED FIELDS MARKED WITH *': 'Please fill in the required fields marked with *',
    "IF NOTHING IS UPLOADED, THE UNIT OR ROOM'S COVER WILL BE USED AS THE ADVERTISEMENT COVER BY DEFAULT.": "If nothing is uploaded, the house or room's cover will be used as the advertisement cover by default.",
    "MAID ROOM": "Maid Room",
    'TENANTS': 'Tenants',
    "VISIBLE ONLY TO YOU, NOT VISIBLE TO OTHERS.": "Visible only to you, not visible to others.",
    "MRT STATIONS NAME": "mrt stations name",
    "MRT STATION CAN NOT SET BE NULL": "mrt station can not set be null",
    "BUS STATION CAN NOT SET BE NULL": "bus station can not set be null",
    "BUS STOP NAME": "bus stop name",
    "MY UNITS": "My Units",
    'PENDING REVIEW': 'Pending Review',
    'GENERALLY COMPLETED WITHIN 1-2 BUSINESS DAYS. THANK YOU FOR YOUR PATIENCE!': 'Generally completed within 1-2 business days. Thank you for your patience!',
    'SUBMISSION SUCCESSFUL, CUSTOMER SERVICE WILL COMPLETE THE PROCESS APPROVAL AS SOON AS POSSIBLE. THIS PROCESS MAY TAKE 1-2 WORKING DAYS, PLEASE BE PATIENT.': 'Submission successful, customer service will complete the process approval as soon as possible. This process may take 1-2 working days, please be patient.',
    'SUBMISSION SUCCESSFULLY': 'Submission Successfully',
    "YOU ALREADY HAVE A COMPANY, PLEASE DO NOT CREATE ANOTHER ONE.": "You already have a company, please do not create another one.",
    "ADD UNIT": "Add Unit",
    "ADD ROOM": "Add Room",
    'SERVICE AGREEMENT': 'Service Agreement',
    'CONGRATULATIONS, YOU MEET THE BASIC REQUIREMENTS FOR STARTING A COMPANY.': 'Congratulations, You meet the basic requirements for starting a company.',
    'AND': 'and',
    'I HAVE READ AND AGREE TO THE HOMEJOY': 'I have read and agree to the HomeJoy',
    "CREATED BY": "Created By",
    'ANALYSIS': 'Analysis',
    "UPDATE TIME": "Update Time",
    "CREATE TIME": "Create Time",
    'LISTING LIST': 'Listing List',
    'LISTING': 'Listing',
    "LISTING DETAILS": "Listing Details",
    'NO LISTINGS HAVE BEEN ADDED YET.': 'No listings have been added yet.',
    USERNAME: "Username",
    "UTILITY CHARGE": "Utility Charge",
    "MIN LEASE TERM": "Min Lease Term",
    "MIN LEASE TERM(MONTHS)": "Min Lease Term(Months)",
    AVAILABILITY: "Availability",
    "AVAILABLE NOW": "Available Now",
    "GENDER PORTFOLIO": "Gender Portfolio",
    'ARTICLE TITLE SHOULD BE LESS THAN 50 WORDS': "Article title should be less than 50 words",
    "LIVE WITH LANDLORD": "Live With Landlord",
    ADDRESS: "Address",
    'DISPLAY USER': 'display user',
    UNDEFINED: "undefined",
    BEDROOMS: "Bedrooms",
    BATHROOMS: "Bathrooms",
    "AREA SQFT": "Area",
    "AREA SQM": "Area(sqm)",
    "AREA UNIT": "Area Unit",
    "BUS STOPS": "Bus Stops",
    "ROOM ID": "Room ID",
    "ROOM LABEL": "Room Label",
    "BED SIZE": "Bed Size",
    "DELETE ROOM": "Delete Room",
    "HAS AC": "Has AC",
    "HAS DESK": "Has Desk",
    "HAS WARDROBE": "Has Wardrobe",
    "HAS WINDOW": "Has Window",
    OPERATION: "Operation",
    SUBMIT: "Submit",
    ADD: "Add",
    "LISTING DESCRIPTION": "Listing Description",
    "THE UPLOADED FILE CANNOT BE LARGER THAN 5M": "The uploaded file cannot be larger than 5M",
    "NEAR CONSTRUCTION SITE": "near construction site",
    "HOMES FOR RENT IN SINGAPORE": "homes for rent in singapore",
    EXT: "ext",
    BLOCK: "Block",
    "ARE YOU SURE TO DELETE THIS UNIT": "are you sure to delete this unit?",
    YES: "yes",
    NO: "no",
    "CLEAR ALL FILTERS": "Clear All Filters",
    SQFT: "sqft",
    SQM: "sqm",
    "CANNOT BE ADDED REPEATEDLY": "Cannot be added repeatedly",
    "MANAGE PICTURE": "Manage Picture",
    "SURROUDING": "Surrouding",
    "PLEASE INPUT ": "please input ",
    "PLEASE INPUT {NAME}": "Please Input {NAME}",
    "PICK AVAILABILITY DAY": "pick availability day",
    "E.G.": "e.g.",
    "_MIN": "Min",
    
    'ARE YOU SURE TO DELETE THIS LISTING?': "Are you sure to delete this listing?",
    "APPLY FILTER": "Apply Filter",
    "MAX": "Max",
    "STATUS": "Status",
    "DISTANCE": "Distance",
    "MEDIA": "Media",
    "PENDING": "Pending",
    "PUBLISHED": "Published",
    "ARTICLE TITLE": "Article Title",
    'IS VISIBLE': 'Is Visible',
    "FAILED": "Failed",
    "UNPUBLISHED": "Unpublished",
    "DELISTED": "Delisted",
    "ARCHIVED": "Archived",
    'THE MAXIMUM PRICE IS NOT ALLOWED TO BE LESS THAN THE MINIMUM PRICE': "The maximum price is not allowed to be less than the minimum price",
    "PLEASE INPUT FORMAT": "please input format: ",
    'SEARCH UNIT BY ID OR POSTCODE': "Search Unit by ID or Postcode",
    "ADD BUS STOP": "Add bus stop",
    'MESSAGE': 'Message',
    "ADD MRT STATION": "Add mrt station",
    M: "m",
    'LAND SIZE': 'Land Size',
    'CS INFORMATION': 'Customer Information',
    'PROJECT INFORMATION': 'Project Information',
    'DISTRICT': 'District',
    'UPDATE POI SUCCESS': 'Update Poi Success',
    'ENTER YOUR SCHOOL/CONDO/DISTRICT/': 'Enter your school/condo/district/',
    MO: "month",
    "NO DESCRIPTION OF THIS HOUSE HAS BEEN RELEASED YET": "no description of this house has been released yet",
    'CONGRATULATIONS, YOU HAVE COMPLETED THE ENTIRE PROCESS OF BECOMING A LANDLORD.': "Congratulations, you have completed the entire process of becoming a landlord.",
    "SELECT LANGUAGE": "Select language",
    "LOGIN SUCCESS": "Login sucessfully",
    'USER AGREEMENT': 'User Agreement',
    'SUCCESS': 'Success',
    'WELCOME TO {WEBNAME}': 'Welcome to {WEBNAME}',
    'LOOKS LIKE YOU DON`T HAVE AN ACCOUNT WITH THAT EMAIL ADDRESS, SIGN UP TO GET ALL OF THESE BENEFITS:': 'Looks like you don`t have an account with that email address, sign up to get all of these benefits:',
    'LOG IN WITH VERIFICATION CODE': 'Log In With Verification Code',
    'CHINESE': 'Chinese',
    'ENGLISH': 'English',
    'POIS': 'POIs',
    'CREDENTIAL INSPECTION': 'Credential Inspection',
    'FIRST NAME': 'First Name',
    'LAST NAME': 'Last Name',
    'FULL NAME': 'Full Name',
    "ADD POI": 'Add POI',
    'ROLE': 'Role',
    'SUPERUSER': 'Superuser',
    'USER': 'User',
    'CANNOT DISABLE YOUR OWN ACCOUNT': 'Cannot disable your own account',
    'GLOBAL_CS': 'Global CS',
    'DISABLE USER': 'Disable User',
    'ENABLE USER': 'Enable User',
    'DISABLE': 'Disable',
    'MODIFY USER': 'Modify User',
    'APPLY': 'Apply',
    'DELETE USER': 'Delete User',
    'TYPE': 'Type',
    'FILTER LIST': 'Filter List',
    'PLEASE WAIT {COUNT} SECONDS': "Please wait {COUNT} seconds",
    'ENABLE': 'Enable',
    'PLEASE VERIFY DOCUMENT INFORMATION': 'Please verify document information',
    'VERIFICATION SUCCESSED': 'Verification Successed',
    'VERIFICATION FAILED': 'Verification Failed',
    'VERIFYING...':'Verifying...',
    'PASS EXPIRE DATE': 'Pass Expire Date',
    'DOCUMENT IMAGE': 'Document Image',
    'DISABLED ACCOUNT': 'Disabled Account',
    'INFORMATION EXTRACTION FAILED': 'Information extraction failed',
    'ENABLED ACCOUNT': 'Enabled Account',
    'CREATED SUCCESSFULLY, YOU CAN DIRECTLY ACCESS THE DETAILS PAGE. THERE IS A DELAY IN UPLOADING PICTURES AND VIDEOS, PLEASE WAIT PATIENTLY...': 'Created successfully, you can directly access the details page. There is a delay in uploading pictures and videos, please wait patiently...',
    'URL HAS BEEN COPIED TO THE PASTEBOARD': 'URL has been copied to the pasteboard',
    'YOUR BROWSER DOES NOT SUPPORT CLIPBOARD API': 'Your browser does not support clipboard api',
    'CHECK ACCOUNT INFORMATION': 'Check account information',
    "INVALID VERIFICATION CODE": "Invalid verification code",
    "REGISTRITION SUCCESS": "Registration Successfully",
    'CONTACT ME':'Contact Me',
    "REGISTRITION FAILURE": "Registration Failed",
    'DOCUMENT TYPE': 'Document Type',
    'DOCUMENT NUMBER': 'Document Number',
    "TO CAMPAIGN PAGE": "To campaign page",
    'IT SEEMS THAT THE SAME POSTCODE ALREADY EXISTS. DO YOU WANT TO VIEW OR CONTINUE CREATING?': 'It seems that the same postcode already exists. Do you want to view or continue creating?',
    "DETECT WECHAT": "Detect WeChat ID",
    "DETECT WHATSAPP": "Detect Whatsapp Account",
    BEDROOM_1: "Beds",
    'PLEASE SELECT A ROOM': 'Please select a room',
    'SELECT UNIT': 'Select Unit',
    'SELECT ROOM': 'Select Room',
    'COMPANY NAME': 'Company Name',
    'COMPANY POSITION': 'Company Position',
    'PLEASE SELECT A UNIT': 'Please select a unit',
    BATHROOM_1: "Baths",
    "VIEW IMAGE": "View Images",
    'DETAIL': 'Detail',
    "WECHAT QR": "WeChat QR code",
    'GALLERY': 'Gallery',
    "WECHAT ACCOUNT": "WeChat ID",
    "COPY LINK": 'Copy Link',
    "_LINK": 'Link',
    PUBLISH: "Publish",
    'LISTING DETAIL': 'Listing Detail',
    "UNIT INFO": "Unit Information",
    "CUSTOMER INFORMATION": 'Customer Information',
    "FORCE OFF SHELVES": "Force Unpublish",
    "NAMING ROOM": "Naming your room",
    "ADS INFO": "ADs Information",
    "ROOM INFO": "Room Information",
    "YOUR FIRST UPLOADED IMAGE WILL BE DISPLAYED ON HOMEPAGE": "Your first uploaded image will be displayed on homepage",
    "PREVIEW IMAGE": "Preview Image",
    "MODIFY USER IDENTITY": "Modify User Identity",
    "IS ACTIVE": "is acitve",
    "SEARCH USERNAME OR EMAIL": "Please enter username or email to search",
    "ENTER NEW USER EMAIL": "Please enter new user email",
    "ENTER NEW USER NICKNAME": "Please enter new user nickname",
    "UPLOAD AVATAR": "Upload Avatar",
    "ENTER NEW USER PHONE NUMBER": "Please enter new user phone number",
    "SELECT NEW USER IDENTITY": "Welect new user identity",
    OPTIONAL_1: "Optional",
    "UPDATE WHATSAPP SUCCESS": "Update whatsapp success",
    "ENTER NEW USER WECHAT ID": "Please enter new user wechat id",
    "UPLOAD WECHAT QR CODE": "Upload Wechat QR code",
    'COMPLETION YEAR': 'Completion Year',
    "ENTER REAL ESTATE LICENSE": "Please enter real estate license",
    "ENTER FOREIGN SINGAPORE ID": "Please enter FIN",
    'POSTCODES': 'Postcodes',
    "ENTER LOCAL ID": "Please enter NRIC",
    'PROJECT TYPE': 'Project Type',
    "ENTER AGENT LICENSE": "Please enter agent license",
    "ENTER NEW USER WHATSAPP": "Please enter new user WhatsApp",
    "ENTER ID REAL NAME": "Please enter full name as per id document",
    "ENTER CUSTOMER SERVICE NAME": "Please enter customer service name",
    "EXISTING IDENTITIES": "Existing Identities",
    "ALL IDENTITIES": "All Identities",
    "WECHAT ID": "Wechat ID",
    'USED FOR HOMEPAGE DISPLAY AND URL PATH. IF NOT FILLED IN, THE ARTICLE TITLE WILL BE USED BY DEFAULT.': 'Used for homepage display and url path. If not filled in, the article title will be used by default.',
    "PHONE": "Phone",
    'VISA STATUS': 'Visa Status',
    'CHECK AT': 'Check At',
    'LEASE FROM': 'Lease From',
    'LEASE TO': 'Lease To',
    'ARTICLE CONTENT': 'Article Content',
    'ARTICLE INDEX NAME': 'Aritcle Index Name',
    "IS WHITELIST": "Is Whitelist",
    "UPDATE AVATAR SUCCESS": "Update Avatar Successfully",
    "UPDATE AVATAR FAILURE": "Update Avatar Failed",
    "CHANGE PICTURE": "Change Picture",
    "UPLOAD PICTURE": "Upload Picture",
    "BIND ID CARD": "Bind ID Card",
    "ALREADY BOUND": "Already Bound",
    "CURRENTLY UNBOUND WHATSAPP": "Currently Unbound Whatsapp",
    "INCOMPLETE INFORMATION": "Incomplete nformation",
    "CURRENTLY UNBOUND WECHAT": "Currently Unbound Wechat",
    "RESET": "Reset",
    "ROLES": "Roles",
    'SELECT FILE': "Select File",
    'BACKGROUND': 'Background'
}
