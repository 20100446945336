export var web_name = "HomeJoy"
export var server_protocol = "https://"
export var server_host = "homejoy.sg"
export var server_port = 443
export var common_response_code = 10000
export var map_key = "4v9zzZTCr50A0DDaXC5jsbMr"
export var show_bus = false
export var verify_failed_code = 10013
export var max_request_body_size = 30 * 1024 * 1024 // 30MB
export const mapTilerApiKey = "qGtlZ83VYAo4niJdY9zp"
export const videoDuration = 60 // seconds
export const videoWidth = 720 // pixels
export const Default_Point = [
    {
        lat: 1.30169,
        lng: 103.83833,
        name: "AGI",
        address: "228 Orchard Rd, #228, Singapore 238853",
        type: "school",
    },
    {
        lat: 1.28828,
        lng: 103.77944,
        name: "Curtin",
        address: "10 Science Park Road, Singapore 117684",
        type: "school",
    },
    {
        lat: 1.32808,
        lng: 103.84574,
        name: "EASB",
        address: "9 Ah Hood Rd, Singapore 329975",
        type: "school",
    },
    {
        lat: 1.30006,
        lng: 103.78644,
        name: "INSEAD",
        address: "1 Ayer Rajah Ave, Singapore 138676",
        type: "school",
    },
    {
        lat: 1.30289,
        lng: 103.7877,
        name: "ESSEC",
        address: "5 Nepal Park, Singapore 139408",
        type: "school",
    },
    {
        lat: 1.31587,
        lng: 103.87581,
        name: "JCU",
        address: "149 Sims Dr, Singapore 387380",
        type: "school",
    },
    {
        lat: 1.30229,
        lng: 103.84961,
        name: "KAPLAN",
        address: "8 Wilkie Rd, Singapore 228095",
        type: "school",
    },
    {
        lat: 1.29698,
        lng: 103.80115,
        name: "MDIS",
        address: "501 Stirling Rd, Singapore 148951",
        type: "school",
    },
    {
        lat: 1.29987,
        lng: 103.85147,
        name: "NAFA",
        address: "80 Bencoolen St, Singapore 189655",
        type: "school",
    },
    {
        lat: 1.29612,
        lng: 103.78133,
        name: "NUS",
        address: "1E Kent Ridge Rd, Level 11, Singapore 119228",
        type: "school",
    },
    {
        lat: 1.34494,
        lng: 103.68157,
        name: "NTU",
        address: "52 Nanyang Ave, Singapore 639798",
        type: "school",
    },
    {
        lat: 1.29098,
        lng: 103.85765,
        name: "PSB",
        address: "6 Raffles Blvd, #03 - 200, Singapore 039594",
        type: "school",
    },
    {
        lat: 1.29621,
        lng: 103.84359,
        name: "SHRM",
        address: "120 Oxley Rise, Singapore 238709",
        type: "school",
    },
    {
        lat: 1.32956,
        lng: 103.77623,
        name: "SIM",
        address: "461 Clementi Rd, Singapore 599491",
        type: "school",
    },
    {
        lat: 1.29658,
        lng: 103.85197,
        name: "SMU",
        address: "81 Victoria St, Singapore 188065",
        type: "school",
    },
    {
        lat: 1.29615,
        lng: 103.81557,
        name: "Stanfort Academy",
        address: "1 Kay Siang Rd, Singapore 248922",
        type: "school",
    },
    {
        lat: 1.29931,
        lng: 103.84816,
        name: "SOTA",
        address: "1 Zubir Said Dr, Singapore 227968",
        type: "school",
    },
    {
        lat: 1.31524,
        lng: 103.89202,
        name: "TMC",
        address: "805 Geylang Rd, Singapore 389683",
        type: "school",
    },
]
