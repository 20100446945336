import { createStore } from 'vuex'
import user from "@/store/user"

const store = createStore({

    // 将app和user放在store中
    modules: {
        user
    }
})

export default store